import * as React from 'react';
import { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  InputLabel,
  Typography,
} from '@mui/material';
import liff from '@line/liff';
import moment from 'moment';

class SocialInsuranceFormProps {
  insurance: any;
  prev: string | undefined;
}

const SocialInsuranceConfirm: React.FC<SocialInsuranceFormProps> = ({
  insurance,
  prev,
}) => {
  const [formState] = useState({
    isValid: false,
    values: {
      lastname: insurance.properties.lastname || '',
      firstname: insurance.properties.firstname || '',
      staffCode: insurance.properties.staff_code || '',

      // 社会保険
      socialTarget: insurance.properties.social_target || '',
      basicPensionNumber: insurance.properties.basic_pension_number || '',
      relatives: insurance.properties.relatives || '',
      socialOtherCompany:
        insurance.properties.social_insurance_other_company || '',
      foreignNationality: insurance.properties.foreign_nationality || '',

      // 雇用保険
      unemploymentTarget: insurance.properties.unemployment_target || '',
      knowUnemploymentNumber:
        insurance.properties.know_unemployment_number || '',
      unemploymentNumber: insurance.properties.unemployment_number || '',
      getInsurance: insurance.properties.get_insurance || '',
      companyName: insurance.properties.company_name || '',
      unemploymentOtherCompany:
        insurance.properties.unemployment_other_company || '',

      // 被扶養者1
      relativesLastname1: insurance.properties.relatives_lastname1 || '',
      relativesLastnameRuby1:
        insurance.properties.relatives_lastname_ruby1 || '',
      relativesFirstname1: insurance.properties.relatives_firstname1 || '',
      relativesFirstnameRuby1:
        insurance.properties.relatives_firstname_ruby1 || '',
      relativesBirthday1: insurance.properties.relatives_birthday1 || '',
      relativesRelationship1:
        insurance.properties.relatives_relationship1 || '',

      // 被扶養者2
      relativesLastname2: insurance.properties.relatives_lastname2 || '',
      relativesLastnameRuby2:
        insurance.properties.relatives_lastname_ruby2 || '',
      relativesFirstname2: insurance.properties.relatives_firstname2 || '',
      relativesFirstnameRuby2:
        insurance.properties.relatives_firstname_ruby2 || '',
      relativesBirthday2: insurance.properties.relatives_birthday2 || '',
      relativesRelationship2:
        insurance.properties.relatives_relationship2 || '',

      // 被扶養者3
      relativesLastname3: insurance.properties.relatives_lastname3 || '',
      relativesLastnameRuby3:
        insurance.properties.relatives_lastname_ruby3 || '',
      relativesFirstname3: insurance.properties.relatives_firstname3 || '',
      relativesFirstnameRuby3:
        insurance.properties.relatives_firstname_ruby3 || '',
      relativesBirthday3: insurance.properties.relatives_birthday3 || '',
      relativesRelationship3:
        insurance.properties.relatives_relationship3 || '',

      // 被扶養者4
      relativesLastname4: insurance.properties.relatives_lastname4 || '',
      relativesLastnameRuby4:
        insurance.properties.relatives_lastname_ruby4 || '',
      relativesFirstname4: insurance.properties.relatives_firstname4 || '',
      relativesFirstnameRuby4:
        insurance.properties.relatives_firstname_ruby4 || '',
      relativesBirthday4: insurance.properties.relatives_birthday4 || '',
      relativesRelationship4:
        insurance.properties.relatives_relationship4 || '',

      // 被扶養者5
      relativesLastname5: insurance.properties.relatives_lastname5 || '',
      relativesLastnameRuby5:
        insurance.properties.relatives_lastname_ruby5 || '',
      relativesFirstname5: insurance.properties.relatives_firstname5 || '',
      relativesFirstnameRuby5:
        insurance.properties.relatives_firstname_ruby5 || '',
      relativesBirthday5: insurance.properties.relatives_birthday5 || '',
      relativesRelationship5:
        insurance.properties.relatives_relationship5 || '',

      // 被扶養者6
      relativesLastname6: insurance.properties.relatives_lastname6 || '',
      relativesLastnameRuby6:
        insurance.properties.relatives_lastname_ruby6 || '',
      relativesFirstname6: insurance.properties.relatives_firstname6 || '',
      relativesFirstnameRuby6:
        insurance.properties.relatives_firstname_ruby6 || '',
      relativesBirthday6: insurance.properties.relatives_birthday6 || '',
      relativesRelationship6:
        insurance.properties.relatives_relationship6 || '',

      // 被扶養者7
      relativesLastname7: insurance.properties.relatives_lastname7 || '',
      relativesLastnameRuby7:
        insurance.properties.relatives_lastname_ruby7 || '',
      relativesFirstname7: insurance.properties.relatives_firstname7 || '',
      relativesFirstnameRuby7:
        insurance.properties.relatives_firstname_ruby7 || '',
      relativesBirthday7: insurance.properties.relatives_birthday7 || '',
      relativesRelationship7:
        insurance.properties.relatives_relationship7 || '',

      // 被扶養者8
      relativesLastname8: insurance.properties.relatives_lastname8 || '',
      relativesLastnameRuby8:
        insurance.properties.relatives_lastname_ruby8 || '',
      relativesFirstname8: insurance.properties.relatives_firstname8 || '',
      relativesFirstnameRuby8:
        insurance.properties.relatives_firstname_ruby8 || '',
      relativesBirthday8: insurance.properties.relatives_birthday8 || '',
      relativesRelationship8:
        insurance.properties.relatives_relationship8 || '',

      // 被扶養者9
      relativesLastname9: insurance.properties.relatives_lastname9 || '',
      relativesLastnameRuby9:
        insurance.properties.relatives_lastname_ruby9 || '',
      relativesFirstname9: insurance.properties.relatives_firstname9 || '',
      relativesFirstnameRuby9:
        insurance.properties.relatives_firstname_ruby9 || '',
      relativesBirthday9: insurance.properties.relatives_birthday9 || '',
      relativesRelationship9:
        insurance.properties.relatives_relationship9 || '',

      // 被扶養者10
      relativesLastname10: insurance.properties.relatives_lastname10 || '',
      relativesLastnameRuby10:
        insurance.properties.relatives_lastname_ruby10 || '',
      relativesFirstname10: insurance.properties.relatives_firstname10 || '',
      relativesFirstnameRuby10:
        insurance.properties.relatives_firstname_ruby10 || '',
      relativesBirthday10: insurance.properties.relatives_birthday10 || '',
      relativesRelationship10:
        insurance.properties.relatives_relationship10 || '',

      //資格確認書発行の有無
      check_myna_cert: insurance.properties.check_myna_cert || '',

      //資格確認書発行の有無
      reason_myna_cert: insurance.properties.reason_myna_cert || '',
    },
    errors: {},
    touched: {},
  });

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <span>
      <div style={{ display: prev === 'top' ? 'none' : '' }}>
        <AppBar
          position="static"
          elevation={0}
          style={{
            alignItems: 'center',
            backgroundColor: '#6ba5b4',
          }}
        >
          <Toolbar>
            <Typography variant="h6" component="div">
              {'社会保険・雇用保険加入手続きの確認'}
            </Typography>
          </Toolbar>
        </AppBar>
      </div>
      <div
        style={{
          display:
            (formState.values.unemploymentTarget === 'いいえ' ||
              formState.values.unemploymentOtherCompany === 'はい') &&
            formState.values.socialTarget === 'いいえ'
              ? 'none'
              : '',
        }}
      >
        <div
          style={{
            display: prev === 'default' ? 'none' : '',
          }}
        >
          <InputLabel
            sx={{
              // mt: 4,
              color: '#0f1941',
              fontSize: '1.1em',
              fontWeight: 'bold',
              textAlign: 'center',
            }}
          >
            {'社会保険・雇用保険手続き'}
          </InputLabel>
        </div>
        <Box sx={{ m: 2 }}>
          <div>
            <div
              style={{
                border: '1px solid rgba(215,215,215,1)',
                marginTop: '1em',
              }}
            >
              <span>
                <Box
                  sx={{
                    mt: 2,
                    ml: 2,
                    display: 'flex',
                    justifyContent: 'left',
                    fontSize: '1em',
                  }}
                >
                  <div>
                    管理番号：
                    <span
                      style={{
                        fontWeight: 'bold',
                        color: '#e74536',
                      }}
                    >
                      {insurance.id}
                    </span>
                  </div>
                </Box>
                <Box sx={{ ml: 4 }}>
                  <InputLabel
                    sx={{
                      mt: 2,
                      color: '#0f1941',
                      fontSize: '1em',
                      fontWeight: 'bold',
                    }}
                  >
                    {'申請者情報'}
                  </InputLabel>
                  <div>
                    <InputLabel
                      sx={{
                        mt: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'スタッフコード:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        mt: 2,
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.staffCode}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <div>
                    <InputLabel
                      sx={{
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'氏名:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.lastname} ${formState.values.firstname}`}
                    </InputLabel>
                  </div>
                </Box>
                <Box sx={{ ml: 4 }}>
                  <InputLabel
                    sx={{
                      mt: 2,
                      color: '#0f1941',
                      fontSize: '1em',
                      fontWeight: 'bold',
                    }}
                  >
                    {'社会保険'}
                  </InputLabel>
                  <div>
                    <InputLabel
                      sx={{
                        mt: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'加入対象:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        mt: 2,
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.socialTarget}`}
                    </InputLabel>
                  </div>
                  <Divider />
                  <span
                    style={{
                      display:
                        formState.values.socialTarget === 'はい' ? '' : 'none',
                    }}
                  >
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'他社での加入:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                          fontWeight: 'normal',
                        }}
                      >
                        {`${formState.values.socialOtherCompany}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'基礎年金番号:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.basicPensionNumber}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'外国籍:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                          fontWeight: 'normal',
                        }}
                      >
                        {`${formState.values.foreignNationality}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'被扶養者の有無:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                          fontWeight: 'normal',
                        }}
                      >
                        {`${formState.values.relatives}`}
                      </InputLabel>
                    </div>
                    <div
                      style={{
                        display: formState.values.check_myna_cert ? '' : 'none',
                      }}
                    >
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                          whiteSpace: 'normal',
                          overflow: 'visible',
                          textOverflow: 'initial',
                          verticalAlign: 'top',
                        }}
                      >
                        {'資格確認書の発行:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                          fontWeight: 'normal',
                          whiteSpace: 'normal',
                          overflow: 'visible',
                          textOverflow: 'initial',
                        }}
                      >
                        {`${formState.values.check_myna_cert}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div
                      style={{
                        display: formState.values.reason_myna_cert
                          ? ''
                          : 'none',
                      }}
                    >
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                          whiteSpace: 'normal',
                          overflow: 'visible',
                          textOverflow: 'initial',
                        }}
                      >
                        {'資格確認書の発行が必要な理由:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                          fontWeight: 'normal',
                          whiteSpace: 'normal',
                          overflow: 'visible',
                          textOverflow: 'initial',
                          verticalAlign: 'middele',
                        }}
                      >
                        {`${formState.values.reason_myna_cert}`}
                      </InputLabel>
                    </div>
                  </span>
                </Box>
                <Box sx={{ ml: 4 }}>
                  <Box
                    sx={{
                      display: formState.values.relativesLastname1
                        ? ''
                        : 'none',
                    }}
                  >
                    <InputLabel
                      sx={{
                        mt: 2,
                        color: '#0f1941',
                        fontSize: '1em',
                        fontWeight: 'bold',
                      }}
                    >
                      {'被扶養者1'}
                    </InputLabel>
                    <div>
                      <InputLabel
                        sx={{
                          mt: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'氏名:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          mt: 2,
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesLastname1}　${formState.values.relativesFirstname1}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'氏名(フリガナ):'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesLastnameRuby1}　${formState.values.relativesFirstnameRuby1}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'生年月日:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesBirthday1}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'続柄:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesRelationship1}`}
                      </InputLabel>
                    </div>
                  </Box>
                  <Box
                    sx={{
                      display: formState.values.relativesLastname2
                        ? ''
                        : 'none',
                    }}
                  >
                    <InputLabel
                      sx={{
                        mt: 2,
                        color: '#0f1941',
                        fontSize: '1em',
                        fontWeight: 'bold',
                      }}
                    >
                      {'被扶養者2'}
                    </InputLabel>
                    <div>
                      <InputLabel
                        sx={{
                          mt: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'氏名:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          mt: 2,
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesLastname2}　${formState.values.relativesFirstname2}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'氏名(フリガナ):'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesLastnameRuby2}　${formState.values.relativesFirstnameRuby2}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'生年月日:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesBirthday2}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'続柄:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesRelationship2}`}
                      </InputLabel>
                    </div>
                  </Box>
                  <Box
                    sx={{
                      display: formState.values.relativesLastname3
                        ? ''
                        : 'none',
                    }}
                  >
                    <InputLabel
                      sx={{
                        mt: 2,
                        color: '#0f1941',
                        fontSize: '1em',
                        fontWeight: 'bold',
                      }}
                    >
                      {'被扶養者3'}
                    </InputLabel>
                    <div>
                      <InputLabel
                        sx={{
                          mt: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'氏名:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          mt: 2,
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesLastname3}　${formState.values.relativesFirstname3}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'氏名(フリガナ):'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesLastnameRuby3}　${formState.values.relativesFirstnameRuby3}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'生年月日:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesBirthday3}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'続柄:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesRelationship3}`}
                      </InputLabel>
                    </div>
                  </Box>
                  <Box
                    sx={{
                      display: formState.values.relativesLastname4
                        ? ''
                        : 'none',
                    }}
                  >
                    <InputLabel
                      sx={{
                        mt: 2,
                        color: '#0f1941',
                        fontSize: '1em',
                        fontWeight: 'bold',
                      }}
                    >
                      {'被扶養者4'}
                    </InputLabel>
                    <div>
                      <InputLabel
                        sx={{
                          mt: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'氏名:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          mt: 2,
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesLastname4}　${formState.values.relativesFirstname4}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'氏名(フリガナ):'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesLastnameRuby4}　${formState.values.relativesFirstnameRuby4}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'生年月日:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesBirthday4}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'続柄:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesRelationship4}`}
                      </InputLabel>
                    </div>
                  </Box>
                  <Box
                    sx={{
                      display: formState.values.relativesLastname5
                        ? ''
                        : 'none',
                    }}
                  >
                    <InputLabel
                      sx={{
                        mt: 2,
                        color: '#0f1941',
                        fontSize: '1em',
                        fontWeight: 'bold',
                      }}
                    >
                      {'被扶養者5'}
                    </InputLabel>
                    <div>
                      <InputLabel
                        sx={{
                          mt: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'氏名:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          mt: 2,
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesLastname5}　${formState.values.relativesFirstname5}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'氏名(フリガナ):'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesLastnameRuby5}　${formState.values.relativesFirstnameRuby5}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'生年月日:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesBirthday5}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'続柄:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesRelationship5}`}
                      </InputLabel>
                    </div>
                  </Box>
                  <Box
                    sx={{
                      display: formState.values.relativesLastname6
                        ? ''
                        : 'none',
                    }}
                  >
                    <InputLabel
                      sx={{
                        mt: 2,
                        color: '#0f1941',
                        fontSize: '1em',
                        fontWeight: 'bold',
                      }}
                    >
                      {'被扶養者6'}
                    </InputLabel>
                    <div>
                      <InputLabel
                        sx={{
                          mt: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'氏名:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          mt: 2,
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesLastname6}　${formState.values.relativesFirstname6}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'氏名(フリガナ):'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesLastnameRuby6}　${formState.values.relativesFirstnameRuby6}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'生年月日:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesBirthday6}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'続柄:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesRelationship6}`}
                      </InputLabel>
                    </div>
                  </Box>
                  <Box
                    sx={{
                      display: formState.values.relativesLastname7
                        ? ''
                        : 'none',
                    }}
                  >
                    <InputLabel
                      sx={{
                        mt: 2,
                        color: '#0f1941',
                        fontSize: '1em',
                        fontWeight: 'bold',
                      }}
                    >
                      {'被扶養者7'}
                    </InputLabel>
                    <div>
                      <InputLabel
                        sx={{
                          mt: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'氏名:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          mt: 2,
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesLastname7}　${formState.values.relativesFirstname7}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'氏名(フリガナ):'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesLastnameRuby7}　${formState.values.relativesFirstnameRuby7}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'生年月日:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesBirthday7}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'続柄:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesRelationship7}`}
                      </InputLabel>
                    </div>
                  </Box>
                  <Box
                    sx={{
                      display: formState.values.relativesLastname8
                        ? ''
                        : 'none',
                    }}
                  >
                    <InputLabel
                      sx={{
                        mt: 2,
                        color: '#0f1941',
                        fontSize: '1em',
                        fontWeight: 'bold',
                      }}
                    >
                      {'被扶養者8'}
                    </InputLabel>
                    <div>
                      <InputLabel
                        sx={{
                          mt: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'氏名:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          mt: 2,
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesLastname8}　${formState.values.relativesFirstname8}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'氏名(フリガナ):'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesLastnameRuby8}　${formState.values.relativesFirstnameRuby8}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'生年月日:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesBirthday8}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'続柄:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesRelationship8}`}
                      </InputLabel>
                    </div>
                  </Box>
                  <Box
                    sx={{
                      display: formState.values.relativesLastname9
                        ? ''
                        : 'none',
                    }}
                  >
                    <InputLabel
                      sx={{
                        mt: 2,
                        color: '#0f1941',
                        fontSize: '1em',
                        fontWeight: 'bold',
                      }}
                    >
                      {'被扶養者9'}
                    </InputLabel>
                    <div>
                      <InputLabel
                        sx={{
                          mt: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'氏名:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          mt: 2,
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesLastname9}　${formState.values.relativesFirstname9}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'氏名(フリガナ):'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesLastnameRuby9}　${formState.values.relativesFirstnameRuby9}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'生年月日:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesBirthday9}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'続柄:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesRelationship9}`}
                      </InputLabel>
                    </div>
                  </Box>
                  <Box
                    sx={{
                      display: formState.values.relativesLastname10
                        ? ''
                        : 'none',
                    }}
                  >
                    <InputLabel
                      sx={{
                        mt: 2,
                        color: '#0f1941',
                        fontSize: '1em',
                        fontWeight: 'bold',
                      }}
                    >
                      {'被扶養者10'}
                    </InputLabel>
                    <div>
                      <InputLabel
                        sx={{
                          mt: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'氏名:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          mt: 2,
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesLastname10}　${formState.values.relativesFirstname10}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'氏名(フリガナ):'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesLastnameRuby10}　${formState.values.relativesFirstnameRuby10}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'生年月日:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesBirthday10}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'続柄:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.relativesRelationship10}`}
                      </InputLabel>
                    </div>
                  </Box>
                </Box>
                <Box sx={{ ml: 4 }}>
                  <InputLabel
                    sx={{
                      mt: 2,
                      color: '#0f1941',
                      fontSize: '1em',
                      fontWeight: 'bold',
                    }}
                  >
                    {'雇用保険'}
                  </InputLabel>
                  <div>
                    <InputLabel
                      sx={{
                        mt: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        width: '40%',
                        textAlign: 'right',
                        display: 'inline-block',
                      }}
                    >
                      {'加入対象:'}
                    </InputLabel>
                    <InputLabel
                      sx={{
                        mt: 2,
                        ml: 2,
                        color: '#0f1941',
                        fontSize: '0.9em',
                        textDecoration: 'underline',
                        width: '50%',
                        display: 'inline-block',
                      }}
                    >
                      {`${formState.values.unemploymentTarget}`}
                    </InputLabel>
                  </div>
                  <div>
                    <span
                      style={{
                        display:
                          formState.values.unemploymentTarget === 'はい'
                            ? ''
                            : 'none',
                      }}
                    >
                      <Divider />
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'他社での加入:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.unemploymentOtherCompany}`}
                      </InputLabel>
                    </span>
                    <Divider />
                    <span
                      style={{
                        display: formState.values.knowUnemploymentNumber
                          ? ''
                          : 'none',
                      }}
                    >
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'雇用保険番号:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {formState.values.knowUnemploymentNumber === `はい`
                          ? `${formState.values.unemploymentNumber}`
                          : `分からない`}
                      </InputLabel>
                    </span>
                    <span
                      style={{
                        display:
                          formState.values.knowUnemploymentNumber === 'いいえ'
                            ? ''
                            : 'none',
                      }}
                    >
                      <Divider />
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'加入実績:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {formState.values.getInsurance === `有り`
                          ? `有り`
                          : `無し`}
                      </InputLabel>
                      <Divider />
                      <span
                        style={{
                          display:
                            formState.values.getInsurance === '有り'
                              ? ''
                              : 'none',
                        }}
                      >
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'企業名:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.companyName}`}
                        </InputLabel>
                      </span>
                    </span>
                  </div>
                </Box>
              </span>
            </div>
            <Box sx={{ mt: 9 }} />
          </div>
        </Box>
      </div>
      <Grid
        item
        xs={7}
        style={{
          textAlign: 'center',
          display: prev === 'top' ? 'none' : '',
        }}
      >
        <AppBar
          position="fixed"
          color="primary"
          sx={{
            top: 'auto',
            bottom: -2,
            backgroundColor: '#1d6ad4',
          }}
        >
          <Grid
            container
            style={{
              height: 55,
            }}
          >
            <React.Fragment>
              <Grid
                item
                xs={6}
                style={{
                  // backgroundColor: activeStep === 0 ? '#d9d9d9' : '#ff3300',
                  backgroundColor: '#d9d9d9',
                  textAlign: 'center',
                }}
              >
                <Button
                  color="inherit"
                  aria-label="back key"
                  sx={{
                    backgroundColor: '#d9d9d9',
                    marginTop: 1,
                    width: '100%',
                    color: '#1d6ad4',
                  }}
                  onClick={() => {
                    const limit = moment(insurance.createdAt)
                      .add(1, 'days')
                      .startOf('day');
                    console.log(limit);

                    const now = moment();
                    // console.log(now);

                    if (now.isBefore(limit)) {
                      window.location.href =
                        window.location.origin +
                        window.location.pathname +
                        '?edit=true';
                    } else {
                      handleOpen();
                    }
                  }}
                >
                  <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
                    編集する
                  </span>
                </Button>
              </Grid>
              <Grid
                item
                // sx={{ width: '100%' }}
                xs={6}
                style={{
                  textAlign: 'center',
                }}
              >
                <Button
                  color="inherit"
                  aria-label="next key"
                  sx={{
                    marginTop: 1,
                    width: '100%',
                  }}
                  /*disabled={applied}*/
                  onClick={() => {
                    liff.closeWindow();
                  }}
                >
                  <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
                    {'閉じる'}
                  </span>
                </Button>
              </Grid>
            </React.Fragment>
          </Grid>
          {/*</Toolbar>*/}
        </AppBar>
      </Grid>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'エラー'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            申請が完了しているため編集できません。
            <br />
            変更をご希望の場合は、担当コンサルタントへご連絡ください。
            <br />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>閉じる</Button>
        </DialogActions>
      </Dialog>
    </span>
  );
};

export default SocialInsuranceConfirm;
