import * as React from 'react';
import { useRef, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import {
  Accordion,
  AccordionDetails,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import { userId } from '../utils/liff';
import { useAppDispatch } from '../app/hooks';
import { useCookies } from 'react-cookie';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';

import liff from '@line/liff';
import TextField from '@mui/material/TextField';
import { insuranceContact } from '../features/socialInsurance/socialInsuranceSlice';
import moment from 'moment';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import Popover from '@mui/material/Popover';
import { MobileDatePicker } from '@mui/x-date-pickers';

// @formatter:off
// const steps = ['1', '2', '3', '4', '5', '6'];
const steps = ['1', '2', '3', '4'];

class SocialInsuranceProps {
  profile: any;
  insurance: any;
  edit: boolean | undefined;
  branch: string | undefined;
  next: string | undefined;
  confirm: boolean | undefined;
}

const SocialInsuranceForm: React.FC<SocialInsuranceProps> = ({
  profile,
  insurance,
  edit,
  branch,
  next,
  confirm,
}) => {
  interface formName {
    // 被扶養者情報(被扶養者1)
    relativesLastnameSi1: string;
    relativesLastnameRubySi1: string;
    relativesFirstnameSi1: string;
    relativesFirstnameRubySi1: string;
    relativesBirthdaySi1: string;
    relativesRelationshipSi1: string;

    // 被扶養者情報(被扶養者2)
    relativesLastnameSi2: string;
    relativesLastnameRubySi2: string;
    relativesFirstnameSi2: string;
    relativesFirstnameRubySi2: string;
    relativesBirthdaySi2: string;
    relativesRelationshipSi2: string;

    // 被扶養者情報(被扶養者3)
    relativesLastnameSi3: string;
    relativesLastnameRubySi3: string;
    relativesFirstnameSi3: string;
    relativesFirstnameRubySi3: string;
    relativesBirthdaySi3: string;
    relativesRelationshipSi3: string;

    // 被扶養者情報(被扶養者4)
    relativesLastnameSi4: string;
    relativesLastnameRubySi4: string;
    relativesFirstnameSi4: string;
    relativesFirstnameRubySi4: string;
    relativesBirthdaySi4: string;
    relativesRelationshipSi4: string;

    // 被扶養者情報(被扶養者5)
    relativesLastnameSi5: string;
    relativesLastnameRubySi5: string;
    relativesFirstnameSi5: string;
    relativesFirstnameRubySi5: string;
    relativesBirthdaySi5: string;
    relativesRelationshipSi5: string;

    // 被扶養者情報(被扶養者6)
    relativesLastnameSi6: string;
    relativesLastnameRubySi6: string;
    relativesFirstnameSi6: string;
    relativesFirstnameRubySi6: string;
    relativesBirthdaySi6: string;
    relativesRelationshipSi6: string;

    // 被扶養者情報(被扶養者7)
    relativesLastnameSi7: string;
    relativesLastnameRubySi7: string;
    relativesFirstnameSi7: string;
    relativesFirstnameRubySi7: string;
    relativesBirthdaySi7: string;
    relativesRelationshipSi7: string;

    // 被扶養者情報(被扶養者8)
    relativesLastnameSi8: string;
    relativesLastnameRubySi8: string;
    relativesFirstnameSi8: string;
    relativesFirstnameRubySi8: string;
    relativesBirthdaySi8: string;
    relativesRelationshipSi8: string;

    // 被扶養者情報(被扶養者9)
    relativesLastnameSi9: string;
    relativesLastnameRubySi9: string;
    relativesFirstnameSi9: string;
    relativesFirstnameRubySi9: string;
    relativesBirthdaySi9: string;
    relativesRelationshipSi9: string;

    // 被扶養者情報(被扶養者10)
    relativesLastnameSi10: string;
    relativesLastnameRubySi10: string;
    relativesFirstnameSi10: string;
    relativesFirstnameRubySi10: string;
    relativesBirthdaySi10: string;
    relativesRelationshipSi10: string;

    //資格確認書の発行が必要かどうか
    check_myna_cert: string;
    //資格確認書が必要な理由
    reason_myna_cert: string;
  }
  const dispatch = useAppDispatch();
  const [inputStart, setInputStart] = useState(edit ? true : false);
  const [cookies, setCookie] = useCookies();

  let defaultCount = cookies.count === undefined ? 0 : Number(cookies.count);
  if (edit) {
    if (insurance.properties.relatives_lastname10) {
      defaultCount = 10;
    } else if (insurance.properties.relatives_lastname9) {
      defaultCount = 9;
    } else if (insurance.properties.relatives_lastname8) {
      defaultCount = 8;
    } else if (insurance.properties.relatives_lastname7) {
      defaultCount = 7;
    } else if (insurance.properties.relatives_lastname6) {
      defaultCount = 6;
    } else if (insurance.properties.relatives_lastname5) {
      defaultCount = 5;
    } else if (insurance.properties.relatives_lastname4) {
      defaultCount = 4;
    } else if (insurance.properties.relatives_lastname3) {
      defaultCount = 3;
    } else if (insurance.properties.relatives_lastname2) {
      defaultCount = 2;
    } else if (insurance.properties.relatives_lastname1) {
      defaultCount = 1;
    }
  }
  const [count, setCount] = React.useState(defaultCount);

  const [numbers, setNumbers] = React.useState(
    !cookies.number
      ? count !== 0
        ? [...Array(count).keys()].map((i) => ++i)
        : [1]
      : [...cookies.number],
  );
  console.log(insurance);
  const [errMsg, setErrMsg] = React.useState('');
  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      lastname: profile.lastname,
      firstname: profile.firstname,
      staffCode: profile.staffCode,
      staffCodeDigit:
        branch === 'cs'
          ? edit
            ? insurance.properties.staff_code.length === 6
              ? '6桁'
              : insurance.properties.staff_code.length === 8
              ? '8桁'
              : ''
            : cookies.staffCodeDigit
            ? cookies.staffCodeDigit
            : ''
          : '',
      // staffCodeDigitqq: '',

      // 社会保険
      socialTarget: edit
        ? insurance.properties.social_target ?? ''
        : cookies.socialTarget
        ? cookies.socialTarget
        : '',
      basicPensionNumber1: edit
        ? String(insurance.properties.basic_pension_number).slice(0, 4) ?? ''
        : cookies.basicPensionNumber1
        ? cookies.basicPensionNumber1
        : '',
      basicPensionNumber2: edit
        ? String(insurance.properties.basic_pension_number).slice(-6) ?? ''
        : cookies.basicPensionNumber2
        ? cookies.basicPensionNumber2
        : '',
      relatives: edit
        ? insurance.properties.relatives ?? ''
        : cookies.relatives
        ? cookies.relatives
        : '',
      socialOtherCompany: edit
        ? insurance.properties.social_insurance_other_company ?? ''
        : cookies.socialOtherCompany
        ? cookies.socialOtherCompany
        : '',
      foreignNationality: edit
        ? insurance.properties.foreign_nationality || 'いいえ'
        : cookies.foreignNationality
        ? cookies.foreignNationality
        : 'いいえ',

      // 雇用保険
      unemploymentTarget: edit
        ? insurance.properties.unemployment_target ?? ''
        : cookies.unemploymentTarget
        ? cookies.unemploymentTarget
        : '',
      knowUnemploymentNumber: edit
        ? insurance.properties.know_unemployment_number ?? ''
        : cookies.knowUnemploymentNumber
        ? cookies.knowUnemploymentNumber
        : '',
      unemploymentNumber1: edit
        ? String(insurance.properties.unemployment_number).slice(0, 4) ?? ''
        : cookies.unemploymentNumber1
        ? cookies.unemploymentNumber1
        : '',
      unemploymentNumber2: edit
        ? String(insurance.properties.unemployment_number).slice(4, 10) ?? ''
        : cookies.unemploymentNumber2
        ? cookies.unemploymentNumber2
        : '',
      unemploymentNumber3: edit
        ? String(insurance.properties.unemployment_number).slice(-1) ?? ''
        : cookies.unemploymentNumber3
        ? cookies.unemploymentNumber3
        : '',
      getInsurance: edit
        ? insurance.properties.get_insurance ?? ''
        : cookies.getInsurance
        ? cookies.getInsurance
        : '',
      companyName: edit
        ? insurance.properties.company_name ?? ''
        : cookies.companyName
        ? cookies.companyName
        : '',

      //被扶養者情報(被扶養者1)
      relativesLastnameSi1: edit
        ? insurance.properties.relatives_lastname1 ?? ''
        : cookies.relativesLastnameSi1
        ? cookies.relativesLastnameSi1
        : '',
      relativesLastnameRubySi1: edit
        ? insurance.properties.relatives_lastname_ruby1 ?? ''
        : cookies.relativesLastnameRubySi1
        ? cookies.relativesLastnameRubySi1
        : '',
      relativesFirstnameSi1: edit
        ? insurance.properties.relatives_firstname1 ?? ''
        : cookies.relativesFirstnameSi1
        ? cookies.relativesFirstnameSi1
        : '',
      relativesFirstnameRubySi1: edit
        ? insurance.properties.relatives_firstname_ruby1 ?? ''
        : cookies.relativesFirstnameRubySi1
        ? cookies.relativesFirstnameRubySi1
        : '',
      relativesBirthdaySi1: edit
        ? insurance.properties.relatives_birthday1 ?? ''
        : cookies.relativesBirthdaySi1
        ? cookies.relativesBirthdaySi1
        : '',
      relativesRelationshipSi1: edit
        ? insurance.properties.relatives_relationship1 ?? ''
        : cookies.relativesRelationshipSi1
        ? cookies.relativesRelationshipSi1
        : '',

      // 被扶養者情報(被扶養者2)
      relativesLastnameSi2: edit
        ? insurance.properties.relatives_lastname2 ?? ''
        : cookies.relativesLastnameSi2
        ? cookies.relativesLastnameSi2
        : '',
      relativesLastnameRubySi2: edit
        ? insurance.properties.relatives_lastname_ruby2 ?? ''
        : cookies.relativesLastnameRubySi2
        ? cookies.relativesLastnameRubySi2
        : '',
      relativesFirstnameSi2: edit
        ? insurance.properties.relatives_firstname2 ?? ''
        : cookies.relativesFirstnameSi2
        ? cookies.relativesFirstnameSi2
        : '',
      relativesFirstnameRubySi2: edit
        ? insurance.properties.relatives_firstname_ruby2 ?? ''
        : cookies.relativesFirstnameRubySi2
        ? cookies.relativesFirstnameRubySi2
        : '',
      relativesBirthdaySi2: edit
        ? insurance.properties.relatives_birthday2 ?? ''
        : cookies.relativesBirthdaySi2
        ? cookies.relativesBirthdaySi2
        : '',
      relativesRelationshipSi2: edit
        ? insurance.properties.relatives_relationship2 ?? ''
        : cookies.relativesRelationshipSi2
        ? cookies.relativesRelationshipSi2
        : '',

      // 被扶養者情報(被扶養者3)
      relativesLastnameSi3: edit
        ? insurance.properties.relatives_lastname3 ?? ''
        : cookies.relativesLastnameSi3
        ? cookies.relativesLastnameSi3
        : '',
      relativesLastnameRubySi3: edit
        ? insurance.properties.relatives_lastname_ruby3 ?? ''
        : cookies.relativesLastnameRubySi3
        ? cookies.relativesLastnameRubySi3
        : '',
      relativesFirstnameSi3: edit
        ? insurance.properties.relatives_firstname3 ?? ''
        : cookies.relativesFirstnameSi3
        ? cookies.relativesFirstnameSi3
        : '',
      relativesFirstnameRubySi3: edit
        ? insurance.properties.relatives_firstname_ruby3 ?? ''
        : cookies.relativesFirstnameRubySi3
        ? cookies.relativesFirstnameRubySi3
        : '',
      relativesBirthdaySi3: edit
        ? insurance.properties.relatives_birthday3 ?? ''
        : cookies.relativesBirthdaySi3
        ? cookies.relativesBirthdaySi3
        : '',
      relativesRelationshipSi3: edit
        ? insurance.properties.relatives_relationship3 ?? ''
        : cookies.relativesRelationshipSi3
        ? cookies.relativesRelationshipSi3
        : '',

      // 被扶養者情報(被扶養者4)
      relativesLastnameSi4: edit
        ? insurance.properties.relatives_lastname4 ?? ''
        : cookies.relativesLastnameSi4
        ? cookies.relativesLastnameSi4
        : '',
      relativesLastnameRubySi4: edit
        ? insurance.properties.relatives_lastname_ruby4 ?? ''
        : cookies.relativesLastnameRubySi4
        ? cookies.relativesLastnameRubySi4
        : '',
      relativesFirstnameSi4: edit
        ? insurance.properties.relatives_firstname4 ?? ''
        : cookies.relativesFirstnameSi4
        ? cookies.relativesFirstnameSi4
        : '',
      relativesFirstnameRubySi4: edit
        ? insurance.properties.relatives_firstname_ruby4 ?? ''
        : cookies.relativesFirstnameRubySi4
        ? cookies.relativesFirstnameRubySi4
        : '',
      relativesBirthdaySi4: edit
        ? insurance.properties.relatives_birthday4 ?? ''
        : cookies.relativesBirthdaySi4
        ? cookies.relativesBirthdaySi4
        : '',
      relativesRelationshipSi4: edit
        ? insurance.properties.relatives_relationship4 ?? ''
        : cookies.relativesRelationshipSi4
        ? cookies.relativesRelationshipSi4
        : '',

      // 被扶養者情報(被扶養者5)
      relativesLastnameSi5: edit
        ? insurance.properties.relatives_lastname5 ?? ''
        : cookies.relativesLastnameSi5
        ? cookies.relativesLastnameSi5
        : '',
      relativesLastnameRubySi5: edit
        ? insurance.properties.relatives_lastname_ruby5 ?? ''
        : cookies.relativesLastnameRubySi5
        ? cookies.relativesLastnameRubySi5
        : '',
      relativesFirstnameSi5: edit
        ? insurance.properties.relatives_firstname5 ?? ''
        : cookies.relativesFirstnameSi5
        ? cookies.relativesFirstnameSi5
        : '',
      relativesFirstnameRubySi5: edit
        ? insurance.properties.relatives_firstname_ruby5 ?? ''
        : cookies.relativesFirstnameRubySi5
        ? cookies.relativesFirstnameRubySi5
        : '',
      relativesBirthdaySi5: edit
        ? insurance.properties.relatives_birthday5 ?? ''
        : cookies.relativesBirthdaySi5
        ? cookies.relativesBirthdaySi5
        : '',
      relativesRelationshipSi5: edit
        ? insurance.properties.relatives_relationship5 ?? ''
        : cookies.relativesRelationshipSi5
        ? cookies.relativesRelationshipSi5
        : '',

      // 被扶養者情報(被扶養者6)
      relativesLastnameSi6: edit
        ? insurance.properties.relatives_lastname6 ?? ''
        : cookies.relativesLastnameSi6
        ? cookies.relativesLastnameSi6
        : '',
      relativesLastnameRubySi6: edit
        ? insurance.properties.relatives_lastname_ruby6 ?? ''
        : cookies.relativesLastnameRubySi6
        ? cookies.relativesLastnameRubySi6
        : '',
      relativesFirstnameSi6: edit
        ? insurance.properties.relatives_firstname6 ?? ''
        : cookies.relativesFirstnameSi6
        ? cookies.relativesFirstnameSi6
        : '',
      relativesFirstnameRubySi6: edit
        ? insurance.properties.relatives_firstname_ruby6 ?? ''
        : cookies.relativesFirstnameRubySi6
        ? cookies.relativesFirstnameRubySi6
        : '',
      relativesBirthdaySi6: edit
        ? insurance.properties.relatives_birthday6 ?? ''
        : cookies.relativesBirthdaySi6
        ? cookies.relativesBirthdaySi6
        : '',
      relativesRelationshipSi6: edit
        ? insurance.properties.relatives_relationship6 ?? ''
        : cookies.relativesRelationshipSi6
        ? cookies.relativesRelationshipSi6
        : '',

      // 被扶養者情報(被扶養者7)
      relativesLastnameSi7: edit
        ? insurance.properties.relatives_lastname7 ?? ''
        : cookies.relativesLastnameSi7
        ? cookies.relativesLastnameSi7
        : '',
      relativesLastnameRubySi7: edit
        ? insurance.properties.relatives_lastname_ruby7 ?? ''
        : cookies.relativesLastnameRubySi7
        ? cookies.relativesLastnameRubySi7
        : '',
      relativesFirstnameSi7: edit
        ? insurance.properties.relatives_firstname7 ?? ''
        : cookies.relativesFirstnameSi7
        ? cookies.relativesFirstnameSi7
        : '',
      relativesFirstnameRubySi7: edit
        ? insurance.properties.relatives_firstname_ruby7 ?? ''
        : cookies.relativesFirstnameRubySi7
        ? cookies.relativesFirstnameRubySi7
        : '',
      relativesBirthdaySi7: edit
        ? insurance.properties.relatives_birthday7 ?? ''
        : cookies.relativesBirthdaySi7
        ? cookies.relativesBirthdaySi7
        : '',
      relativesRelationshipSi7: edit
        ? insurance.properties.relatives_relationship7 ?? ''
        : cookies.relativesRelationshipSi7
        ? cookies.relativesRelationshipSi7
        : '',

      // 被扶養者情報(被扶養者8)
      relativesLastnameSi8: edit
        ? insurance.properties.relatives_lastname8 ?? ''
        : cookies.relativesLastnameSi8
        ? cookies.relativesLastnameSi8
        : '',
      relativesLastnameRubySi8: edit
        ? insurance.properties.relatives_lastname_ruby8 ?? ''
        : cookies.relativesLastnameRubySi8
        ? cookies.relativesLastnameRubySi8
        : '',
      relativesFirstnameSi8: edit
        ? insurance.properties.relatives_firstname8 ?? ''
        : cookies.relativesFirstnameSi8
        ? cookies.relativesFirstnameSi8
        : '',
      relativesFirstnameRubySi8: edit
        ? insurance.properties.relatives_firstname_ruby8 ?? ''
        : cookies.relativesFirstnameRubySi8
        ? cookies.relativesFirstnameRubySi8
        : '',
      relativesBirthdaySi8: edit
        ? insurance.properties.relatives_birthday8 ?? ''
        : cookies.relativesBirthdaySi8
        ? cookies.relativesBirthdaySi8
        : '',
      relativesRelationshipSi8: edit
        ? insurance.properties.relatives_relationship8 ?? ''
        : cookies.relativesRelationshipSi8
        ? cookies.relativesRelationshipSi8
        : '',

      // 被扶養者情報(被扶養者9)
      relativesLastnameSi9: edit
        ? insurance.properties.relatives_lastname9 ?? ''
        : cookies.relativesLastnameSi9
        ? cookies.relativesLastnameSi9
        : '',
      relativesLastnameRubySi9: edit
        ? insurance.properties.relatives_lastname_ruby9 ?? ''
        : cookies.relativesLastnameRubySi9
        ? cookies.relativesLastnameRubySi9
        : '',
      relativesFirstnameSi9: edit
        ? insurance.properties.relatives_firstname9 ?? ''
        : cookies.relativesFirstnameSi9
        ? cookies.relativesFirstnameSi9
        : '',
      relativesFirstnameRubySi9: edit
        ? insurance.properties.relatives_firstname_ruby9 ?? ''
        : cookies.relativesFirstnameRubySi9
        ? cookies.relativesFirstnameRubySi9
        : '',
      relativesBirthdaySi9: edit
        ? insurance.properties.relatives_birthday9 ?? ''
        : cookies.relativesBirthdaySi9
        ? cookies.relativesBirthdaySi9
        : '',
      relativesRelationshipSi9: edit
        ? insurance.properties.relatives_relationship9 ?? ''
        : cookies.relativesRelationshipSi9
        ? cookies.relativesRelationshipSi9
        : '',

      // 被扶養者情報(被扶養者10)
      relativesLastnameSi10: edit
        ? insurance.properties.relatives_lastname10 ?? ''
        : cookies.relativesLastnameSi10
        ? cookies.relativesLastnameSi10
        : '',
      relativesLastnameRubySi10: edit
        ? insurance.properties.relatives_lastname_ruby10 ?? ''
        : cookies.relativesLastnameRubySi10
        ? cookies.relativesLastnameRubySi10
        : '',
      relativesFirstnameSi10: edit
        ? insurance.properties.relatives_firstname10 ?? ''
        : cookies.relativesFirstnameSi10
        ? cookies.relativesFirstnameSi10
        : '',
      relativesFirstnameRubySi10: edit
        ? insurance.properties.relatives_firstname_ruby10 ?? ''
        : cookies.relativesFirstnameRubySi10
        ? cookies.relativesFirstnameRubySi10
        : '',
      relativesBirthdaySi10: edit
        ? insurance.properties.relatives_birthday10 ?? ''
        : cookies.relativesBirthdaySi10
        ? cookies.relativesBirthdaySi10
        : '',
      relativesRelationshipSi10: edit
        ? insurance.properties.relatives_relationship10 ?? ''
        : cookies.relativesRelationshipSi10
        ? cookies.relativesRelationshipSi10
        : '',

      branch: cookies.branch ? cookies.branch : '',

      //資格確認書発行の有無
      check_myna_cert: edit
        ? insurance.properties.check_myna_cert ?? ''
        : cookies.check_myna_cert
        ? cookies.check_myna_cert
        : '',

      //資格確認書発行の有無
      reason_myna_cert: edit
        ? insurance.properties.reason_myna_cert ?? ''
        : cookies.reason_myna_cert
        ? cookies.reason_myna_cert
        : '',
    },
    errors: {},
    touched: {},
  });

  const [activeStep, setActiveStep] = React.useState(
    next === 'next2' ? 1 : next === 'next3' ? 2 : 0,
  );
  const [completed] = React.useState<{
    [k: number]: boolean;
  }>({});

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleChange = (event: any, field: any, value: any) => {
    setCookie(field, value);
    // event.persist();
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [field]: value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  // スタッフコードのエラー文字
  let errWord2 = '';
  if (formState.values.staffCode === '') {
    errWord2 = '必須項目';
  } else {
    errWord2 = '8桁入力してください';
  }
  let errWord4 = '';
  if (formState.values.staffCode === '') {
    errWord4 = '必須項目';
  } else {
    errWord4 = '6桁入力してください';
  }

  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [open4, setOpen4] = React.useState(false);

  const processing = useRef(false);

  const handleClickOpen = (e: any) => {
    setOpen(true);

    e.preventDefault();

    // 処理中(true)なら非同期処理せずに抜ける
    if (processing.current) {
      handleClose();
      return;
    }

    // 処理中フラグを上げる
    processing.current = true;

    // 疑似非同期処理
    setTimeout(() => {
      // 処理中フラグを下げる
      processing.current = false;
    }, 3000);

    if (String(formState.values.basicPensionNumber1) === 'null') {
      formState.values.basicPensionNumber1 = '';
      formState.values.basicPensionNumber2 = '';
    }

    if (String(formState.values.unemploymentNumber1) === 'null') {
      formState.values.unemploymentNumber1 = '';
      formState.values.unemploymentNumber2 = '';
      formState.values.unemploymentNumber3 = '';
    }

    //被扶養者の有無が無しの時に項目を空白にする
    if (formState.values.socialTarget === 'いいえ') {
      formState.values.foreignNationality = '';
      formState.values.check_myna_cert = '';
      formState.values.reason_myna_cert = '';
    }
    if (branch !== 'cs') {
      formState.values.staffCodeDigit = '';
    }

    dispatch(
      insuranceContact({
        userId: userId(),
        values: formState.values,
        objectId: edit ? insurance.id : null,
      }),
    );
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    window.scrollTo({
      top: 0,
    });
    handleClose();
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen2 = () => {
    setOpen2(true);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };

  const handleOpen3 = () => {
    setOpen3(true);
  };
  const handleClose3 = () => {
    setOpen3(false);
  };

  const handleOpen4 = () => {
    setOpen4(true);
  };
  const handleClose4 = () => {
    setOpen4(false);
  };

  const handleStart = () => {
    setInputStart(!inputStart);
  };

  const handleNext = () => {
    setOpen3(false);
    if (activeStep === 0) {
      if (formState.values.staffCodeDigit === '' && branch === 'cs') {
        setErrMsg('『スタッフコード』が未選択です。');
        handleOpen2();
        return;
      } else if (
        (formState.values.staffCodeDigit === '6桁' &&
          formState.values.staffCode.length !== 6 &&
          branch === 'cs') ||
        (formState.values.staffCodeDigit === '8桁' &&
          formState.values.staffCode.length !== 8 &&
          branch === 'cs') ||
        (formState.values.staffCode.length !== 8 && !(branch === 'cs'))
      ) {
        setErrMsg('『スタッフコード』が未入力です。');
        handleOpen2();
        return;
      }
    }
    if (activeStep === 2) {
      // 必須チェック
      if (formState.values.unemploymentTarget === '') {
        setErrMsg('『雇用保険の加入対象』が未入力です。');
        handleOpen2();
        return;
      } else if (
        formState.values.unemploymentTarget === 'いいえ' &&
        formState.values.socialTarget === 'いいえ'
      ) {
        if (next !== 'default' && !edit) {
          setErrMsg(
            '加入対象ではない場合、社会保険・雇用保険手続きの申請は不要です。スキップをして入職手続きの申請を行ってください。',
          );
          handleOpen4();
        } else {
          setErrMsg(
            '加入対象ではない場合、申請は不要です。アプリを閉じてください。',
          );
          handleOpen2();
        }
        return;
      } else if (
        formState.values.unemploymentTarget === 'はい' &&
        formState.values.knowUnemploymentNumber === ''
      ) {
        setErrMsg('『雇用保険番号』が未入力です。');
        handleOpen2();
        return;
      } else if (formState.values.knowUnemploymentNumber === 'はい') {
        if (
          formState.values.unemploymentNumber1 === '' ||
          formState.values.unemploymentNumber2 === ''
        ) {
          setErrMsg('『雇用保険被保険者番号』が未入力です。');
          handleOpen2();
          return;
        } else if (
          String(formState.values.unemploymentNumber1).length !== 4 ||
          String(formState.values.unemploymentNumber2).length !== 6 ||
          formState.values.unemploymentNumber3 === ''
        ) {
          setErrMsg('有効な『雇用保険被保険者番号』を入力してください。');
          handleOpen2();
          return;
        }
      } else if (
        formState.values.knowUnemploymentNumber === 'いいえ' &&
        formState.values.getInsurance === ''
      ) {
        setErrMsg('『雇用保険の加入実績』が未入力です。');
        handleOpen2();
        return;
      } else if (
        formState.values.getInsurance === '有り' &&
        formState.values.companyName === ''
      ) {
        setErrMsg('『企業名』が未入力です。');
        handleOpen2();
        return;
      }
      if (next !== 'default') {
        if (!edit) {
          window.location.href =
            window.location.origin +
            window.location.pathname +
            '?confirm=true' +
            branchCheck;
        }
      }
    }
    if (activeStep === 1) {
      // 必須チェック
      if (formState.values.socialTarget === '') {
        setErrMsg('『社会保険の加入対象』が未入力です。');
        handleOpen2();
        return;
      } else if (formState.values.socialTarget === 'はい') {
        if (formState.values.socialOtherCompany === '') {
          setErrMsg('『他社での社会保険加入』が未入力です。');
          handleOpen2();
          return;
        } else if (
          formState.values.basicPensionNumber1 === '' ||
          formState.values.basicPensionNumber2 === ''
        ) {
          setErrMsg('『基礎年金番号』が未入力です。');
          handleOpen2();
          return;
        } else if (
          String(formState.values.basicPensionNumber1).length !== 4 ||
          String(formState.values.basicPensionNumber2).length !== 6
        ) {
          setErrMsg('有効な『基礎年金番号』を入力してください。');
          handleOpen2();
          return;
        } else if (formState.values.relatives === '') {
          setErrMsg('『被扶養者の有無』が未入力です。');
          handleOpen2();
          return;
        }
        if (formState.values.relatives === '有り') {
          if (count !== 0) {
            for (let cnt = 1; cnt < count + 1; cnt++) {
              const relativesLastname = handleValue(
                `relativesLastnameSi${cnt}`,
              );
              const relativesLastnameRuby = handleValue(
                `relativesLastnameRubySi${cnt}`,
              );
              const relativesFirstname = handleValue(
                `relativesFirstnameSi${cnt}`,
              );
              const relativesFirstnameRuby = handleValue(
                `relativesFirstnameRubySi${cnt}`,
              );
              const relativesBirthday = handleValue(
                `relativesBirthdaySi${cnt}`,
              );
              const relativesRelationship = handleValue(
                `relativesRelationshipSi${cnt}`,
              );
              // 扶養親族の必須情報
              if (!relativesLastname) {
                setErrMsg(`扶養親族${cnt}の『姓』が未入力です。`);
                handleOpen2();
                return;
              } else if (!relativesFirstname) {
                setErrMsg(`扶養親族${cnt}の『名』が未入力です。`);
                handleOpen2();
                return;
              } else if (!relativesLastnameRuby) {
                setErrMsg(`扶養親族${cnt}の『セイ』が未入力です。`);
                handleOpen2();
                return;
              } else if (!relativesFirstnameRuby) {
                setErrMsg(`扶養親族${cnt}の『メイ』が未入力です。`);
                handleOpen2();
                return;
              } else if (!relativesBirthday) {
                setErrMsg(`扶養親族${cnt}の『誕生日』が未選択です。`);
                handleOpen2();
                return;
              } else if (!relativesRelationship) {
                setErrMsg(`扶養親族${cnt}の『続柄』が未選択です。`);
                handleOpen2();
                return;
              } else {
                handleOpen3();
              }
            }
          } else if (count === 0) {
            setErrMsg('『被扶養者』が未入力です。');
            handleOpen2();
            return;
          }
        }

        if (formState.values.check_myna_cert == '') {
          setErrMsg('資格確認書の発行が必要かどうか選択してください。');
          handleOpen2();
          return;
        }
        if (
          formState.values.check_myna_cert == '『資格確認書』の発行が必要' &&
          formState.values.reason_myna_cert == ''
        ) {
          setErrMsg('資格確認書の発行が必要な理由を選択してください。');
          handleOpen2();
          return;
        }
      }
    }
    if (!(activeStep === 2 && next !== 'default' && !edit)) {
      const newActiveStep =
        isLastStep() && !allStepsCompleted()
          ? steps.findIndex((step, i) => !(i in completed))
          : activeStep + 1;
      setActiveStep(newActiveStep);
      window.scrollTo({
        top: 0,
      });
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    window.scrollTo({
      top: 0,
    });
  };

  const handleValue = (value: any) => {
    const key: keyof formName = value;
    return formState.values[key];
  };

  const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .05)'
        : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  }));

  const [expanded, setExpanded] = React.useState<string | false>(false);
  const handleAccordion =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const doubled = numbers.map((number) => {
    return (
      <Accordion
        sx={{ boxShadow: 'none' }}
        expanded={expanded === 'panel' + number}
        onChange={handleAccordion('panel' + number)}
        key={number}
      >
        <AccordionSummary
          sx={{
            width: '100%',
            border: 'solid 0.5px #e6e6e6',
            backgroundColor: '#ffffff',
          }}
          aria-controls={`panel${number}d-content`}
          id={`panel${number}d-header`}
        >
          <Typography>被扶養者{number}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <div>
              <TextField
                id="standard-basic"
                label="姓"
                variant="standard"
                value={handleValue(`relativesLastnameSi${number}`)}
                error={handleValue(`relativesLastnameSi${number}`) === ''}
                onChange={(event) => {
                  handleChange(
                    event,
                    `relativesLastnameSi${number}`,
                    event.target.value,
                  );
                }}
                sx={{ mt: 2, mr: 2, mb: 2, width: '40%' }}
                placeholder="山田"
                helperText={
                  handleValue(`relativesLastnameSi${number}`) === ''
                    ? '必須項目'
                    : false
                }
              />
              <TextField
                id="standard-basic"
                label="名"
                variant="standard"
                value={handleValue(`relativesFirstnameSi${number}`)}
                error={handleValue(`relativesFirstnameSi${number}`) === ''}
                onChange={(event) => {
                  handleChange(
                    event,
                    `relativesFirstnameSi${number}`,
                    event.target.value,
                  );
                }}
                sx={{ mt: 2, ml: 2, mr: 2, mb: 2, width: '40%' }}
                placeholder="太郎"
                helperText={
                  handleValue(`relativesFirstnameSi${number}`) === ''
                    ? '必須項目'
                    : false
                }
              />
            </div>
            <div>
              <TextField
                id="standard-basic"
                label="セイ"
                variant="standard"
                value={handleValue(`relativesLastnameRubySi${number}`)}
                error={handleValue(`relativesLastnameRubySi${number}`) === ''}
                onChange={(event) => {
                  handleChange(
                    event,
                    `relativesLastnameRubySi${number}`,
                    event.target.value,
                  );
                }}
                sx={{ mt: 2, mr: 2, mb: 2, width: '40%' }}
                placeholder="ﾔﾏﾀﾞ"
                helperText={
                  handleValue(`relativesLastnameRubySi${number}`) === ''
                    ? '必須項目'
                    : false
                }
              />
              <TextField
                id="standard-basic"
                label="メイ"
                variant="standard"
                value={handleValue(`relativesFirstnameRubySi${number}`)}
                error={handleValue(`relativesFirstnameRubySi${number}`) === ''}
                onChange={(event) => {
                  handleChange(
                    event,
                    `relativesFirstnameRubySi${number}`,
                    event.target.value,
                  );
                }}
                sx={{ mt: 2, ml: 2, mr: 2, mb: 2, width: '40%' }}
                placeholder="ﾀﾛｳ"
                helperText={
                  handleValue(`relativesFirstnameRubySi${number}`) === ''
                    ? '必須項目'
                    : false
                }
              />
            </div>
            <div>
              <MobileDatePicker
                label="生年月日"
                inputFormat="yyyy/M/d"
                toolbarFormat="yyyy/M/d"
                value={
                  handleValue(`relativesBirthdaySi${number}`) || '2000/1/1'
                }
                maxDate={new Date()}
                onChange={(date) => {
                  console.log(date);
                  handleChange(
                    {
                      target: {
                        name: `relativesBirthdaySi${number}`,
                      },
                    },
                    `relativesBirthdaySi${number}`,
                    moment(date).format('YYYY-MM-DD'),
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    variant="standard"
                    sx={{ mt: 2, mr: 2, mb: 2, width: '40%' }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={!handleValue(`relativesBirthdaySi${number}`)}
                    helperText={
                      !handleValue(`relativesBirthdaySi${number}`)
                        ? '必須項目'
                        : false
                    }
                    {...params}
                  />
                )}
              />
            </div>
            <div>
              <InputLabel
                sx={{ mt: 2, color: '#0f1941', fontSize: '0.8em' }}
                id="relationship-label"
              >
                {'続柄'}
              </InputLabel>
              <FormControl
                sx={{ mt: 1, mr: 2, mb: 2, width: '90%' }}
                error={handleValue(`relativesRelationshipSi${number}`) === ''}
              >
                <Select
                  labelId="relationship-label"
                  id="relationship-select"
                  variant="standard"
                  value={
                    String(
                      handleValue(`relativesRelationshipSi${number}`),
                    ).substring(0, 3) === 'その他'
                      ? 'その他'
                      : handleValue(`relativesRelationshipSi${number}`)
                  }
                  onChange={(event) => {
                    console.log(event.target.value);
                    handleChange(
                      event,
                      `relativesRelationshipSi${number}`,
                      event.target.value,
                    );
                  }}
                >
                  <MenuItem value={''} />
                  <MenuItem value={'夫'}>夫</MenuItem>
                  <MenuItem value={'妻'}>妻</MenuItem>
                  <MenuItem value={'長男'}>長男</MenuItem>
                  <MenuItem value={'次男'}>次男</MenuItem>
                  <MenuItem value={'三男'}>三男</MenuItem>
                  <MenuItem value={'長女'}>長女</MenuItem>
                  <MenuItem value={'次女'}>次女</MenuItem>
                  <MenuItem value={'三女'}>三女</MenuItem>
                  <MenuItem value={'四男'}>四男</MenuItem>
                  <MenuItem value={'四女'}>四女</MenuItem>
                  <MenuItem value={'五男'}>五男</MenuItem>
                  <MenuItem value={'五女'}>五女</MenuItem>
                  <MenuItem value={'六男'}>六女</MenuItem>
                  <MenuItem value={'子'}>子</MenuItem>
                  <MenuItem value={'父'}>父</MenuItem>
                  <MenuItem value={'母'}>母</MenuItem>
                  <MenuItem value={'義父'}>義父</MenuItem>
                  <MenuItem value={'義母'}>義母</MenuItem>
                  <MenuItem value={'兄'}>兄</MenuItem>
                  <MenuItem value={'姉'}>姉</MenuItem>
                  <MenuItem value={'弟'}>弟</MenuItem>
                  <MenuItem value={'妹'}>妹</MenuItem>
                  <MenuItem value={'祖父'}>祖父</MenuItem>
                  <MenuItem value={'祖母'}>祖母</MenuItem>
                  <MenuItem value={'曾祖父'}>曾祖父</MenuItem>
                  <MenuItem value={'甥'}>甥</MenuItem>
                  <MenuItem value={'姪'}>姪</MenuItem>
                  <MenuItem value={'叔父'}>叔父</MenuItem>
                  <MenuItem value={'叔母'}>叔母</MenuItem>
                  <MenuItem value={'伯父'}>伯父</MenuItem>
                  <MenuItem value={'伯母'}>伯母</MenuItem>
                  <MenuItem value={'孫'}>孫</MenuItem>
                  <MenuItem value={'曾孫'}>曾孫</MenuItem>
                  <MenuItem value={'いとこ'}>いとこ</MenuItem>
                  <MenuItem value={'はとこ'}>はとこ</MenuItem>
                  <MenuItem value={'その他'}>その他</MenuItem>
                </Select>
                <FormHelperText
                  style={{
                    display:
                      handleValue(`relativesRelationshipSi${number}`) === ''
                        ? ''
                        : 'none',
                  }}
                >
                  必須項目
                </FormHelperText>
              </FormControl>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    );
  });

  const [popoverAnchorEl, setPopoverAnchorEl] =
    React.useState<SVGSVGElement | null>(null);
  const [popoverContent, setPopoverContent] = React.useState(0);
  const [showPopover, setPopover] = React.useState(false);
  const handleClosePopover = () => {
    setPopoverAnchorEl(null);
    setPopover(false);
  };
  const popovers = [
    <div>
      基礎年金番号は10桁の数字で表され、4桁と6桁の組み合わせとなっています。
      <br />
      年金手帳あるいは基礎年金番号通知書に記載されています。
    </div>,
    <div>
      雇用保険被保険者番号は11桁の数字で表され、4桁・6桁・1桁の組み合わせとなっています。
      <br />
      雇用保険被保険者証に記載されています。
    </div>,
  ];

  const DR =
    formState.values.branch === 'DR北関東支店（DR小山課/DR高崎課）' ||
    formState.values.branch === 'DR東京・千葉支店（DR新宿課/DR船橋課）' ||
    formState.values.branch === 'DR埼玉支店（DRさいたま課/DR所沢課）' ||
    formState.values.branch === 'DR神奈川支店（DR横浜課/DR厚木課）' ||
    formState.values.branch === 'DR名阪支店（DR名古屋課/DR大阪課）';

  const branchCheck =
    branch && !(branch === 'default') ? `&branch=${branch}` : '';

  // console.log(insurance.properties.relatives_lastname10);

  return (
    <span>
      <AppBar
        position="static"
        elevation={0}
        style={{
          alignItems: 'center',
          backgroundColor: '#6ba5b4',
          display: confirm ? 'none' : '',
        }}
      >
        <Toolbar>
          <Typography variant="h6" component="div">
            {activeStep === 0 && !inputStart && next === 'default'
              ? 'ごあいさつ'
              : ''}
            {(activeStep === 0 && inputStart) ||
            (activeStep === 0 && next !== 'default')
              ? '申請者の確認'
              : ''}
            {activeStep === 1 || activeStep === 2
              ? '社会保険・雇用保険加入手続き'
              : ''}
            {activeStep === 3 ? '確認画面' : ''}
            {activeStep === 4 ? 'お疲れ様でした' : ''}
          </Typography>
        </Toolbar>
      </AppBar>
      <Box sx={{ m: 2 }}>
        <Box sx={{ mr: 8, ml: 8 }}>
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            sx={{
              display:
                confirm ||
                (activeStep === 0 && next === 'default' && !edit && !inputStart)
                  ? 'none'
                  : '',
            }}
          >
            {steps.map((label) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: React.ReactNode;
              } = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps} />
                </Step>
              );
            })}
          </Stepper>
        </Box>
        {activeStep === 0 && !inputStart && next === 'default' && !confirm ? (
          <span>
            <div
              style={{
                border: '1px solid rgba(215,215,215,1)',
                padding: '1em',
              }}
            >
              <div
                style={{
                  color: '#696969',
                  fontSize: '13px',
                  marginBottom: '1em',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'right',
                }}
              >
                {moment().format('YYYY年M月D日')}
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img src="/randstad-logo.png" alt="logo" width="80%" />
              </div>
              <br />
              この度は、ご入社おめでとうございます。
              こちらは、社会保険・雇用保険手続き申請アプリです。
              <br />
              <br />
              <span
                style={{
                  color: '#e74536',
                }}
              >
                必ずご就業が確定された方のみお手続きをお願いします。
                <br />
              </span>
              <br />
              社会保険・雇用保険のいずれか１つ以上が加入対象となっている場合は、「次へ」を押して入力画面にお進みください。
              <br />
              <br />
              <span
                style={{
                  fontWeight: 'bold',
                }}
              >
                登録にあたり以下を確認させていただきます。お手数ですが、確認できる書類を事前にご準備ください。
              </span>
              <br />
              <br />
              ■社会保険の加入手続き
              <br />
              ・基礎年金番号
              <br />
              <br />
              ■雇用保険の加入手続き
              <br />
              ・雇用保険被保険者番号
              <br />
              ＊番号が分からない場合は、過去の就業歴からお調べいたしますので企業名をご確認させていただきます。
              <br />
              <br />
              LINEで申告がご不安な方
              <br />
              社会保険センターでも承ります。お手数ですが以下までご連絡ください。
              <br />
              <br />
              <span
                style={{
                  color: '#1d6ad4',
                  display: branch === 'default' ? '' : 'none',
                }}
              >
                社会保険センター 0120-940-911
                <br />
                （平日9:00〜18:00）
              </span>
              <span
                style={{
                  color: '#1d6ad4',
                  display: branch === 'cs' ? '' : 'none',
                }}
              >
                新宿業務管理部社会保険センター
                <br />
                03-5326-7258
                <br />
                （平日 9：00～18：00）
              </span>
            </div>
            <Box sx={{ mt: 9 }} />
          </span>
        ) : (
          ''
        )}
        {activeStep === 2 ? (
          <div>
            <div
              style={{
                border: '1px solid rgba(215,215,215,1)',
                marginTop: '1em',
              }}
            >
              <Box component="form" noValidate autoComplete="off">
                <div>
                  <FormControl
                    sx={{ mt: 2, ml: 4, mr: 2, mb: 0, width: '80%' }}
                    error={
                      formState.values.unemploymentTarget === '' ||
                      cookies.unemploymentTarget === ''
                    }
                  >
                    <FormLabel
                      id="unemployment-label"
                      style={{ fontSize: '.8em', color: '#0f1941' }}
                    >
                      雇用保険の加入対象でしょうか？
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={formState.values.unemploymentTarget}
                      onChange={(event) => {
                        console.log(event.target.value);
                        handleChange(
                          event,
                          'unemploymentTarget',
                          event.target.value,
                        );
                        handleChange(event, 'knowUnemploymentNumber', '');
                        handleChange(event, 'unemploymentNumber1', '');
                        handleChange(event, 'unemploymentNumber2', '');
                        handleChange(event, 'unemploymentNumber3', '');
                        handleChange(event, 'getInsurance', '');
                        handleChange(event, 'companyName', '');
                      }}
                    >
                      <FormControlLabel
                        value="はい"
                        control={<Radio />}
                        label="はい"
                      />
                      <FormControlLabel
                        value="いいえ"
                        control={<Radio />}
                        label="いいえ"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
                <div
                  style={{
                    display:
                      formState.values.unemploymentTarget === 'いいえ'
                        ? ''
                        : 'none',
                  }}
                >
                  <Box
                    sx={{
                      ml: 4,
                      fontSize: '0.7em',
                      width: '80%',
                      color: '#e74536',
                    }}
                  >
                    加入対象ではない場合、こちらの申請は不要です。
                    <br />
                    次へお進みください。
                  </Box>
                </div>
                <div
                  style={{
                    display:
                      formState.values.unemploymentTarget === 'はい'
                        ? ''
                        : 'none',
                  }}
                >
                  <FormControl
                    sx={{ mt: 2, ml: 4, mr: 2, mb: 0, width: '80%' }}
                    error={
                      formState.values.knowUnemploymentNumber === '' ||
                      cookies.knowUnemploymentNumber === ''
                    }
                  >
                    <FormLabel
                      id="unemployment-label"
                      style={{ fontSize: '.8em', color: '#0f1941' }}
                    >
                      雇用保険番号はお分かりですか？
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={formState.values.knowUnemploymentNumber}
                      onChange={(event) => {
                        console.log(event.target.value);
                        handleChange(
                          event,
                          'knowUnemploymentNumber',
                          event.target.value,
                        );
                        handleChange(event, 'unemploymentNumber1', '');
                        handleChange(event, 'unemploymentNumber2', '');
                        handleChange(event, 'unemploymentNumber3', '');
                        handleChange(event, 'getInsurance', '');
                        handleChange(event, 'companyName', '');
                      }}
                    >
                      <FormControlLabel
                        value="はい"
                        control={<Radio />}
                        label="はい"
                      />
                      <FormControlLabel
                        value="いいえ"
                        control={<Radio />}
                        label="いいえ"
                      />
                    </RadioGroup>
                  </FormControl>
                  <div
                    style={{
                      display:
                        formState.values.knowUnemploymentNumber === 'はい'
                          ? ''
                          : 'none',
                    }}
                  >
                    <FormControl
                      sx={{ mt: 2, ml: 4, mr: 2, mb: 0, width: '80%' }}
                    >
                      <div>
                        <FormLabel
                          id="unemployment-label"
                          style={{ fontSize: '.8em', color: '#0f1941' }}
                        >
                          雇用保険被保険者番号（11桁）
                        </FormLabel>
                        <InfoIcon
                          sx={{
                            fontSize: 16,
                            color: '#2175d9',
                            verticalAlign: 'middle',
                          }}
                          onClick={(event) => {
                            setPopoverAnchorEl(event.currentTarget);
                            setPopover(true);
                            setPopoverContent(1);
                          }}
                        />
                      </div>
                    </FormControl>
                    <div>
                      <TextField
                        id="staff-code"
                        variant="standard"
                        type="tel"
                        placeholder="0000"
                        value={formState.values.unemploymentNumber1}
                        error={
                          String(formState.values.unemploymentNumber1)
                            .length !== 4 ||
                          (!edit &&
                            String(cookies.unemploymentNumber1).length !== 4)
                        }
                        inputProps={{ maxLength: '4' }}
                        onChange={(event) => {
                          console.log(event.target.value);
                          const onlyNums = String(event.target.value).replace(
                            /[^0-9]/g,
                            '',
                          );
                          console.log(onlyNums);
                          handleChange(event, 'unemploymentNumber1', onlyNums);
                        }}
                        sx={{ mt: 0, ml: 4, mr: 1, mb: 0, width: '20%' }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        helperText={
                          String(formState.values.unemploymentNumber1)
                            .length !== 4 ||
                          (!edit &&
                            String(cookies.unemploymentNumber1).length !== 4)
                            ? '4桁入力してください'
                            : false
                        }
                      />
                      <span style={{ fontSize: '1.4em', color: '#a9a9a9' }}>
                        -
                      </span>
                      <TextField
                        id="staff-code"
                        variant="standard"
                        type="tel"
                        placeholder="000000"
                        value={formState.values.unemploymentNumber2}
                        error={
                          String(formState.values.unemploymentNumber2)
                            .length !== 6 ||
                          (!edit &&
                            String(cookies.unemploymentNumber2).length !== 6)
                        }
                        inputProps={{ maxLength: '6' }}
                        onChange={(event) => {
                          console.log(event.target.value);
                          const onlyNums = String(event.target.value).replace(
                            /[^0-9]/g,
                            '',
                          );
                          console.log(onlyNums);
                          handleChange(event, 'unemploymentNumber2', onlyNums);
                        }}
                        sx={{ mt: 0, ml: 1, mr: 1, mb: 0, width: '25%' }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        helperText={
                          String(formState.values.unemploymentNumber2)
                            .length !== 6 ||
                          (!edit &&
                            String(cookies.unemploymentNumber2).length !== 6)
                            ? '6桁入力してください'
                            : false
                        }
                      />
                      <span style={{ fontSize: '1.4em', color: '#a9a9a9' }}>
                        -
                      </span>
                      <TextField
                        id="staff-code"
                        variant="standard"
                        type="tel"
                        placeholder="0"
                        value={formState.values.unemploymentNumber3}
                        error={
                          String(formState.values.unemploymentNumber3)
                            .length !== 1 ||
                          (!edit &&
                            String(cookies.unemploymentNumber3).length !== 1)
                        }
                        inputProps={{ maxLength: '1' }}
                        onChange={(event) => {
                          console.log(event.target.value);
                          const onlyNums = String(event.target.value).replace(
                            /[^0-9]/g,
                            '',
                          );
                          console.log(onlyNums);
                          handleChange(event, 'unemploymentNumber3', onlyNums);
                        }}
                        sx={{ mt: 0, ml: 1, mr: 0, mb: 0, width: '20%' }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        helperText={
                          String(formState.values.unemploymentNumber3)
                            .length !== 1 ||
                          (!edit &&
                            String(cookies.unemploymentNumber3).length !== 1)
                            ? '1桁入力してください'
                            : false
                        }
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display:
                        formState.values.knowUnemploymentNumber === 'いいえ'
                          ? ''
                          : 'none',
                    }}
                  >
                    <FormControl
                      sx={{ mt: 2, ml: 4, mr: 2, mb: 0, width: '80%' }}
                      error={
                        formState.values.getInsurance === '' ||
                        cookies.getInsurance === ''
                      }
                    >
                      <FormLabel
                        id="unemployment-label"
                        style={{ fontSize: '.8em', color: '#0f1941' }}
                      >
                        過去の就業先で雇用保険の加入実績はございますか？
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={formState.values.getInsurance}
                        onChange={(event) => {
                          console.log(event.target.value);
                          handleChange(
                            event,
                            'getInsurance',
                            event.target.value,
                          );
                          handleChange(event, 'unemploymentNumber1', '');
                          handleChange(event, 'unemploymentNumber2', '');
                          handleChange(event, 'unemploymentNumber3', '');
                          handleChange(event, 'companyName', '');
                        }}
                      >
                        <FormControlLabel
                          value="有り"
                          control={<Radio />}
                          label="有り"
                        />
                        <FormControlLabel
                          value="無し"
                          control={<Radio />}
                          label="無し"
                        />
                      </RadioGroup>
                    </FormControl>
                    <div
                      style={{
                        display:
                          formState.values.getInsurance === '有り'
                            ? ''
                            : 'none',
                      }}
                    >
                      <FormControl
                        sx={{ mt: 2, ml: 4, mr: 2, mb: 0, width: '80%' }}
                      >
                        <FormLabel
                          id="unemployment-label"
                          style={{ fontSize: '.8em', color: '#0f1941' }}
                        >
                          お調べしますので、過去の就業先の企業名を入力してください。
                        </FormLabel>
                      </FormControl>
                      <FormControl
                        sx={{ mt: 2, ml: 4, mr: 0, mb: 0, width: '80%' }}
                        error={
                          formState.values.companyName === '' ||
                          cookies.companyName === ''
                        }
                      >
                        <FormLabel
                          id="unemployment-label"
                          style={{ fontSize: '.8em', color: '#0f1941' }}
                        >
                          企業名
                        </FormLabel>
                        <Box
                          sx={{
                            fontSize: '0.7em',
                            color: '#e74536',
                          }}
                        >
                          ＊派遣就業の場合は派遣元企業名を記載してください
                        </Box>
                        <TextField
                          id="staff-code"
                          variant="standard"
                          placeholder="◯◯株式会社"
                          value={formState.values.companyName}
                          onChange={(event) => {
                            console.log(event.target.value);
                            handleChange(
                              event,
                              'companyName',
                              event.target.value,
                            );
                          }}
                          sx={{ width: '80%' }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </FormControl>
                    </div>
                  </div>
                </div>
              </Box>
              <br />
            </div>
            <Box sx={{ mt: 9 }} />
          </div>
        ) : (
          ''
        )}
        {(activeStep === 0 && !confirm && next !== 'default') ||
        (activeStep === 0 && inputStart) ? (
          <div>
            <div
              style={{
                border: '1px solid rgba(215,215,215,1)',
                marginTop: '1em',
              }}
            >
              <Box
                sx={{
                  mt: 2,
                  ml: 4,
                  fontSize: '0.8em',
                  width: '80%',
                }}
              >
                確認のため、申請者ご自身のスタッフコード、および氏名に間違えがないかどうか確認ください。
              </Box>
              <FormControl
                sx={{ mt: 2, ml: 2, mr: 2, mb: 1, width: '80%' }}
                error={
                  formState.values.socialTarget === '' ||
                  cookies.socialTarget === ''
                }
              >
                <div
                  style={{
                    display: branch === 'cs' ? '' : 'none',
                  }}
                >
                  <FormControl
                    sx={{ mt: 2, ml: 2, mr: 2, mb: 1, width: '80%' }}
                    error={
                      formState.values.staffCodeDigit === '' ||
                      cookies.staffCodeDigit === ''
                    }
                  >
                    <FormLabel
                      id="gendar-label"
                      style={{ fontSize: '.8em', color: '#0f1941' }}
                    >
                      スタッフコードの桁数を選択
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={formState.values.staffCodeDigit}
                      onChange={(event) => {
                        console.log(event.target.value);
                        handleChange(
                          event,
                          'staffCodeDigit',
                          event.target.value,
                        );
                      }}
                    >
                      <FormControlLabel
                        value="6桁"
                        control={<Radio />}
                        label="6桁"
                      />
                      <FormControlLabel
                        value="8桁"
                        control={<Radio />}
                        label="8桁"
                      />
                    </RadioGroup>
                    <FormHelperText
                      style={{
                        display:
                          formState.values.staffCodeDigit === '' ||
                          cookies.staffCodeDigit === ''
                            ? ''
                            : 'none',
                      }}
                    >
                      必須項目
                    </FormHelperText>
                  </FormControl>
                </div>
                <div
                  style={{
                    display:
                      !(branch === 'cs') ||
                      (branch === 'cs' && formState.values.staffCodeDigit)
                        ? ''
                        : 'none',
                  }}
                >
                  <TextField
                    id="staff-code"
                    variant="standard"
                    label="スタッフコード"
                    type="tel"
                    placeholder={
                      formState.values.staffCodeDigit === '6桁'
                        ? '000000'
                        : '00000000'
                    }
                    value={formState.values.staffCode}
                    error={
                      formState.values.staffCodeDigit === '6桁' &&
                      branch === 'cs'
                        ? String(formState.values.staffCode).length !== 6 ||
                          (!edit && String(cookies.staffCode).length !== 6)
                        : String(formState.values.staffCode).length !== 8 ||
                          (!edit && String(cookies.staffCode).length !== 8)
                    }
                    inputProps={{
                      maxLength:
                        formState.values.staffCodeDigit === '6桁' &&
                        branch === 'cs'
                          ? '6'
                          : '8',
                    }}
                    onChange={(event) => {
                      console.log(event.target.value);
                      const onlyNums = String(event.target.value).replace(
                        /[^0-9]/g,
                        '',
                      );
                      console.log(onlyNums);
                      handleChange(event, 'staffCode', onlyNums);
                    }}
                    sx={{ mt: 2, ml: 2, mr: 2, mb: 2, width: '35%' }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    helperText={
                      formState.values.staffCodeDigit === '6桁' &&
                      branch === 'cs'
                        ? String(formState.values.staffCode).length !== 6 ||
                          (!edit && String(cookies.staffCode).length !== 6)
                          ? errWord4
                          : false
                        : String(formState.values.staffCode).length !== 8 ||
                          (!edit && String(cookies.staffCode).length !== 8)
                        ? errWord2
                        : false
                    }
                  />
                </div>
                <div>
                  <TextField
                    id="standard-basic"
                    label="姓"
                    variant="standard"
                    value={formState.values.lastname}
                    onChange={(event) => {
                      handleChange(event, 'lastname', event.target.value);
                    }}
                    sx={{ mt: 2, ml: 2, mr: 2, mb: 2, width: '35%' }}
                    placeholder="山田"
                  />
                  <TextField
                    id="standard-basic"
                    label="名"
                    variant="standard"
                    value={formState.values.firstname}
                    onChange={(event) => {
                      handleChange(event, 'firstname', event.target.value);
                    }}
                    sx={{ mt: 2, ml: 2, mr: 2, mb: 2, width: '35%' }}
                    placeholder="太郎"
                  />
                </div>
              </FormControl>
              <Box
                sx={{
                  ml: 4,
                  mb: 1,
                  fontSize: '0.8em',
                  width: '80%',
                }}
              >
                ※間違いがある場合は修正後に次へ進んでください。
              </Box>
            </div>
            <Box sx={{ mt: 9 }} />
          </div>
        ) : (
          ''
        )}
        {activeStep === 1 ? (
          <div>
            <div
              style={{
                border: '1px solid rgba(215,215,215,1)',
                marginTop: '1em',
              }}
            >
              <Box component="form" noValidate autoComplete="off">
                <div>
                  <FormControl
                    sx={{ mt: 2, ml: 4, mr: 2, mb: 0, width: '80%' }}
                    error={
                      formState.values.socialTarget === '' ||
                      cookies.socialTarget === ''
                    }
                  >
                    <FormLabel
                      id="social-label"
                      style={{ fontSize: '.8em', color: '#0f1941' }}
                    >
                      社会保険の加入対象でしょうか？
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={formState.values.socialTarget}
                      onChange={(event) => {
                        console.log(event.target.value);
                        handleChange(event, 'socialTarget', event.target.value);
                        handleChange(event, 'basicPensionNumber1', '');
                        handleChange(event, 'basicPensionNumber2', '');
                        handleChange(event, 'relatives', '');
                        handleChange(event, 'socialOtherCompany', '');
                      }}
                    >
                      <FormControlLabel
                        value="はい"
                        control={<Radio />}
                        label="はい"
                      />
                      <FormControlLabel
                        value="いいえ"
                        control={<Radio />}
                        label="いいえ"
                      />
                    </RadioGroup>
                  </FormControl>
                  <Box
                    sx={{
                      ml: 4,
                      fontSize: '0.7em',
                      width: '80%',
                      // color: '#d32f2f',
                    }}
                  >
                    社会保険加入情報を既に書面やメール等でご連絡頂いている場合はご登録は不要です。
                    <br />
                    <br />
                  </Box>
                </div>
                <div
                  style={{
                    display:
                      formState.values.socialTarget === 'いいえ' ? '' : 'none',
                  }}
                >
                  <Box
                    sx={{
                      ml: 4,
                      fontSize: '0.7em',
                      width: '80%',
                      color: '#d32f2f',
                    }}
                  >
                    加入対象ではない場合、こちらの申請は不要です。
                    <br />
                    次へお進みください。
                  </Box>
                </div>
                <div
                  style={{
                    display:
                      formState.values.socialTarget === 'はい' ? '' : 'none',
                  }}
                >
                  <Box
                    sx={{
                      p: '12px',
                      mt: 2,
                      mb: 2,
                      ml: 3,
                      mr: 2,
                      backgroundColor: '#D3E3F7',
                      fontSize: '0.8em',
                    }}
                  >
                    <div style={{ fontWeight: '700' }}>
                      社会保険加入対象の方へ
                    </div>
                    健康保険のご加入にはマイナンバー登録が必要です。※弊社でマイナンバーが未登録の方には、別途、マイナンバーご登録案内のメール「【重要】マイナンバーご登録のお願い（ランスタッド）」よりマイナンバー登録を行ってください。
                  </Box>
                  <Box
                    sx={{
                      ml: 4,
                      mb: 1,
                      fontSize: '0.7em',
                      width: '80%',
                      color: '#d32f2f',
                    }}
                  >
                    加入対象の場合、基礎年金番号の提出が必要です。
                    <br />
                    以下に入力してください。
                  </Box>
                  <FormControl
                    sx={{ mt: 2, ml: 4, mr: 2, mb: 0, width: '80%' }}
                    error={
                      formState.values.socialOtherCompany === '' ||
                      cookies.socialOtherCompany === ''
                    }
                  >
                    <FormLabel
                      id="social-label"
                      style={{ fontSize: '.8em', color: '#0f1941' }}
                    >
                      ≪ご契約期間中に他社でもご就業される方≫
                      <br />
                      ご契約期間中、他社での社会保険の加入はございますか？
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={formState.values.socialOtherCompany}
                      onChange={(event) => {
                        console.log(event.target.value);
                        handleChange(
                          event,
                          'socialOtherCompany',
                          event.target.value,
                        );
                      }}
                    >
                      <FormControlLabel
                        value="はい"
                        control={<Radio />}
                        label="はい"
                      />
                      <FormControlLabel
                        value="いいえ"
                        control={<Radio />}
                        label="いいえ"
                      />
                    </RadioGroup>
                  </FormControl>
                  <FormControl
                    sx={{ mt: 2, ml: 4, mr: 2, mb: 0, width: '80%' }}
                    error={
                      formState.values.socialTarget === '' ||
                      cookies.socialTarget === ''
                    }
                  >
                    <div>
                      <FormLabel
                        id="social-label"
                        style={{ fontSize: '.8em', color: '#0f1941' }}
                      >
                        基礎年金番号（10桁）
                      </FormLabel>
                      <InfoIcon
                        sx={{
                          fontSize: 16,
                          color: '#2175d9',
                          verticalAlign: 'middle',
                        }}
                        onClick={(event) => {
                          setPopoverAnchorEl(event.currentTarget);
                          setPopover(true);
                          setPopoverContent(0);
                        }}
                      />
                    </div>
                  </FormControl>
                  <div>
                    <TextField
                      id="staff-code"
                      variant="standard"
                      type="tel"
                      placeholder="0000"
                      value={formState.values.basicPensionNumber1}
                      error={
                        String(formState.values.basicPensionNumber1).length !==
                          4 ||
                        (!edit &&
                          String(cookies.basicPensionNumber1).length !== 4)
                      }
                      inputProps={{ maxLength: '4' }}
                      onChange={(event) => {
                        console.log(event.target.value);
                        const onlyNums = String(event.target.value).replace(
                          /[^0-9]/g,
                          '',
                        );
                        console.log(onlyNums);
                        handleChange(event, 'basicPensionNumber1', onlyNums);
                      }}
                      sx={{ mt: 0, ml: 4, mr: 1, mb: 0, width: '35%' }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      helperText={
                        String(formState.values.basicPensionNumber1).length !==
                          4 ||
                        (!edit &&
                          String(cookies.basicPensionNumber1).length !== 4)
                          ? '4桁入力してください'
                          : false
                      }
                    />
                    <span style={{ fontSize: '1.4em', color: '#a9a9a9' }}>
                      -
                    </span>
                    <TextField
                      id="staff-code"
                      variant="standard"
                      type="tel"
                      placeholder="000000"
                      value={formState.values.basicPensionNumber2}
                      error={
                        String(formState.values.basicPensionNumber2).length !==
                          6 ||
                        (!edit &&
                          String(cookies.basicPensionNumber2).length !== 6)
                      }
                      inputProps={{ maxLength: '6' }}
                      onChange={(event) => {
                        console.log(event.target.value);
                        const onlyNums = String(event.target.value).replace(
                          /[^0-9]/g,
                          '',
                        );
                        console.log(onlyNums);
                        handleChange(event, 'basicPensionNumber2', onlyNums);
                      }}
                      sx={{ mt: 0, ml: 1, mr: 2, mb: 0, width: '40%' }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      helperText={
                        String(formState.values.basicPensionNumber2).length !==
                          6 ||
                        (!edit &&
                          String(cookies.basicPensionNumber2).length !== 6)
                          ? '6桁入力してください'
                          : false
                      }
                    />
                  </div>
                  <div>
                    <FormControl
                      sx={{ mt: 3, ml: 4, mr: 2, mb: 0, width: '80%' }}
                      error={
                        formState.values.foreignNationality === '' ||
                        cookies.foreignNationality === ''
                      }
                    >
                      <FormLabel
                        id="social-label"
                        style={{ fontSize: '.8em', color: '#0f1941' }}
                      >
                        ≪外国籍の方≫
                        <br />
                        外国籍の方は以下のチェックボックスにチェックをいれてください
                      </FormLabel>
                    </FormControl>
                    <Box
                      sx={{
                        mt: 1,
                        ml: 3,
                        color: '#0f1941',
                        fontSize: '1em',
                      }}
                    >
                      <Checkbox
                        checked={formState.values.foreignNationality === 'はい'}
                        onChange={(event) => {
                          handleChange(
                            event,
                            `foreignNationality`,
                            formState.values.foreignNationality === 'はい'
                              ? 'いいえ'
                              : 'はい',
                          );
                          console.log(formState.values.foreignNationality);
                        }}
                      />
                      外国籍
                    </Box>
                  </div>
                  <div>
                    <FormControl
                      sx={{ mt: 3, ml: 4, mr: 2, mb: 0, width: '80%' }}
                      error={
                        formState.values.relatives === '' ||
                        cookies.relatives === ''
                      }
                    >
                      <FormLabel
                        id="relatives-label"
                        style={{ fontSize: '.8em', color: '#0f1941' }}
                      >
                        被扶養者の有無
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={formState.values.relatives}
                        onChange={(event) => {
                          console.log(event.target.value);
                          handleChange(event, 'relatives', event.target.value);
                        }}
                      >
                        <FormControlLabel
                          value="有り"
                          control={<Radio />}
                          label="有り"
                        />
                        <FormControlLabel
                          value="無し"
                          control={<Radio />}
                          label="無し"
                        />
                      </RadioGroup>
                      <Box
                        sx={{
                          fontSize: '0.7em',
                        }}
                      >
                        ※75歳以上の方は「後期高齢者」のため被扶養者の対象にはなりません。
                      </Box>
                    </FormControl>
                  </div>
                  <div
                    style={{
                      display:
                        formState.values.relatives === '有り' ? '' : 'none',
                    }}
                  >
                    <Button
                      style={{ display: count === 10 ? 'none' : '' }}
                      variant="outlined"
                      sx={{
                        ml: 4,
                        mt: 2,
                        width: '80%',
                      }}
                      aria-label="reduce"
                      onClick={() => {
                        setCookie('count', count + 1);
                        setCount(count + 1);
                        if (count >= 1) {
                          setNumbers([...numbers, count + 1]);
                          setCookie('number', [...numbers, count + 1]);
                        }
                      }}
                    >
                      <AddIcon fontSize="small" />
                      {'被扶養者を追加する'}
                    </Button>
                    <Button
                      style={{ display: count === 0 ? 'none' : '' }}
                      variant="outlined"
                      sx={{ ml: 4, mt: 2, width: '80%' }}
                      aria-label="increase"
                      onClick={() => {
                        if (count >= 2) {
                          const newNumber = numbers.filter(
                            (number) => number !== count,
                          );
                          setNumbers(newNumber);
                          setCookie('number', newNumber);
                        }
                        setCookie('count', Math.max(count - 1, 0));
                        setCount(Math.max(count - 1, 0));
                      }}
                    >
                      <RemoveIcon fontSize="small" />
                      {'被扶養者を減らす'}
                    </Button>
                    <div style={{ display: count === 0 ? 'none' : '' }}>
                      <Divider sx={{ mt: 4 }} />
                      <Box sx={{ ml: 2, mt: 2, width: '90%' }}>{doubled}</Box>
                    </div>
                  </div>
                  <Box
                    sx={{
                      ml: 3,
                      mr: 2,
                      mt: 4,
                      fontSize: '0.8em',
                    }}
                  >
                    <div>
                      資格確認書の発行について、以下から選択してください。
                    </div>
                    <Box
                      sx={{
                        p: '12px',
                        mt: 2,
                        mb: 2,
                        backgroundColor: '#D3E3F7',
                      }}
                    >
                      <div style={{ fontWeight: '700' }}>資格確認書とは？</div>
                      マイナ保険証によるオンライン資格確認（医療機関等の受診）を受けることができない状況にある方について医療機関等へ提示することで保険診療を受けられるようにするために申請により交付する書類です。
                    </Box>
                    <Box>
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue={formState.values.check_myna_cert}
                          name="radio-buttons-group"
                          value={formState.values.check_myna_cert}
                          onChange={(event) => {
                            formState.values.reason_myna_cert = '';
                            handleChange(
                              event,
                              'check_myna_cert',
                              event.target.value,
                            );
                          }}
                          style={{
                            display: 'flex',
                            gap: '16px',
                            alignItems: 'start',
                          }}
                        >
                          <FormControlLabel
                            value="マイナ保険証を利用するため、『資格確認書』の発行は要りません"
                            control={<Radio />}
                            label="マイナ保険証を利用するため、『資格確認書』の発行は要りません"
                          />
                          <FormControlLabel
                            value="『資格確認書』の発行が必要"
                            control={<Radio />}
                            label="『資格確認書』の発行が必要"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                    <Box
                      sx={{ mt: 4, mb: 2 }}
                      style={{
                        gap: '12px',
                        flexDirection: 'column',
                        display:
                          formState.values.check_myna_cert ===
                          '『資格確認書』の発行が必要'
                            ? 'flex'
                            : 'none',
                      }}
                    >
                      <div>
                        『資格確認書』の発行が必要とご回答いただいた方は、以下のいずれか１つから理由を選択してください。
                      </div>
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue={formState.values.reason_myna_cert}
                          name="radio-buttons-group"
                          value={formState.values.reason_myna_cert}
                          onChange={(event) => {
                            handleChange(
                              event,
                              'reason_myna_cert',
                              event.target.value,
                            );
                          }}
                          style={{
                            display: 'flex',
                            gap: '16px',
                            alignItems: 'start',
                            justifyContent: 'start',
                          }}
                        >
                          <FormControlLabel
                            value="マイナンバーカードを紛失したため"
                            control={<Radio />}
                            label="マイナンバーカードを紛失したため"
                          />
                          <FormControlLabel
                            value="マイナンバーカードの更新手続き中のため"
                            control={<Radio />}
                            label="マイナンバーカードの更新手続き中のため"
                          />
                          <FormControlLabel
                            value="マイナンバーカードの電子証明書の有効期限が切れているため"
                            control={<Radio />}
                            label="マイナンバーカードの電子証明書の有効期限が切れているため"
                          />
                          <FormControlLabel
                            value="マイナンバーカードを持っているが、健康保険証利用登録を行っていないため"
                            control={<Radio />}
                            label="マイナンバーカードを持っているが、健康保険証利用登録を行っていないため"
                          />
                          <FormControlLabel
                            value="マイナンバーカードを作っていないため"
                            control={<Radio />}
                            label="マイナンバーカードを作っていないため"
                          />
                          <FormControlLabel
                            value="マイナンバーカードを返納したため"
                            control={<Radio />}
                            label="マイナンバーカードを返納したため"
                          />
                          <FormControlLabel
                            value="マイナ保険証による受診には第三者（介助者など）のサポートが必要なため"
                            control={<Radio />}
                            label="マイナ保険証による受診には第三者（介助者など）のサポートが必要なため"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  </Box>
                </div>
              </Box>
              <br />
            </div>
            <Box sx={{ mt: 9 }} />
          </div>
        ) : (
          ''
        )}
        {(activeStep === 3 && !confirm) || (activeStep === 0 && confirm) ? (
          <div>
            <div
              style={{
                display:
                  formState.values.unemploymentTarget === 'いいえ' &&
                  formState.values.socialTarget === 'いいえ'
                    ? ''
                    : 'none',
              }}
            >
              <Box sx={{ mt: 9 }} />
            </div>
            <div
              style={{
                display:
                  formState.values.unemploymentTarget === 'いいえ' &&
                  formState.values.socialTarget === 'いいえ'
                    ? 'none'
                    : '',
              }}
            >
              <div
                style={{
                  display: confirm ? '' : 'none',
                }}
              >
                <InputLabel
                  sx={{
                    mt: 4,
                    color: '#0f1941',
                    fontSize: '1.1em',
                    fontWeight: 'bold',
                    textAlign: 'center',
                  }}
                >
                  {'社会保険・雇用保険手続き'}
                </InputLabel>
              </div>
              <div
                style={{
                  border: '1px solid rgba(215,215,215,1)',
                  marginTop: '1em',
                }}
              >
                <span>
                  <div
                    style={{
                      display: confirm ? 'none' : '',
                    }}
                  >
                    <Box
                      sx={{
                        mt: 2,
                        display: 'flex',
                        justifyContent: 'center',
                        fontSize: '1em',
                      }}
                    >
                      <span>入力内容を確認してください。</span>
                    </Box>
                  </div>
                  <Box
                    sx={{ ml: 4 }}
                    onClick={() => {
                      if (confirm) {
                        window.location.href =
                          window.location.origin +
                          window.location.pathname +
                          '?next=next1' +
                          branchCheck;
                      } else {
                        setActiveStep(0);
                        window.scrollTo({
                          top: 0,
                          // behavior: 'smooth',
                        });
                      }
                    }}
                  >
                    <InputLabel
                      sx={{
                        mt: 2,
                        color: '#0f1941',
                        fontSize: '1em',
                        fontWeight: 'bold',
                      }}
                    >
                      {'申請者情報'}
                    </InputLabel>
                    <div>
                      <InputLabel
                        sx={{
                          mt: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'スタッフコード:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          mt: 2,
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.staffCode}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <div>
                      <InputLabel
                        sx={{
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'氏名:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.lastname} ${formState.values.firstname}`}
                      </InputLabel>
                    </div>
                  </Box>
                  <Box
                    sx={{ ml: 4 }}
                    onClick={() => {
                      if (confirm) {
                        window.location.href =
                          window.location.origin +
                          window.location.pathname +
                          '?next=next2' +
                          branchCheck;
                      } else {
                        setActiveStep(1);
                        window.scrollTo({
                          top: 0,
                          // behavior: 'smooth',
                        });
                      }
                    }}
                  >
                    <InputLabel
                      sx={{
                        mt: 2,
                        color: '#0f1941',
                        fontSize: '1em',
                        fontWeight: 'bold',
                      }}
                    >
                      {'社会保険'}
                    </InputLabel>
                    <div>
                      <InputLabel
                        sx={{
                          mt: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'加入対象:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          mt: 2,
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.socialTarget}`}
                      </InputLabel>
                    </div>
                    <Divider />
                    <span
                      style={{
                        display:
                          formState.values.socialTarget === 'はい'
                            ? ''
                            : 'none',
                      }}
                    >
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'他社での加入:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                            fontWeight: 'normal',
                          }}
                        >
                          {`${formState.values.socialOtherCompany}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'基礎年金番号:'}
                        </InputLabel>
                        <Box
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                            verticalAlign: 'top',
                          }}
                        >
                          {`${formState.values.basicPensionNumber1} - ${formState.values.basicPensionNumber2}`}
                        </Box>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'外国籍:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                            fontWeight: 'normal',
                          }}
                        >
                          {`${formState.values.foreignNationality}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'被扶養者の有無:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                            fontWeight: 'normal',
                          }}
                        >
                          {`${formState.values.relatives}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div
                        style={{
                          display: formState.values.check_myna_cert
                            ? ''
                            : 'none',
                        }}
                      >
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                            whiteSpace: 'normal',
                            overflow: 'visible',
                            textOverflow: 'initial',
                            verticalAlign: 'top',
                          }}
                        >
                          {'資格確認書の発行:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                            fontWeight: 'normal',
                            whiteSpace: 'normal',
                            overflow: 'visible',
                            textOverflow: 'initial',
                          }}
                        >
                          {`${formState.values.check_myna_cert}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div
                        style={{
                          display: formState.values.reason_myna_cert
                            ? ''
                            : 'none',
                        }}
                      >
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                            whiteSpace: 'normal',
                            overflow: 'visible',
                            textOverflow: 'initial',
                          }}
                        >
                          {'資格確認書の発行が必要な理由:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                            fontWeight: 'normal',
                            whiteSpace: 'normal',
                            overflow: 'visible',
                            textOverflow: 'initial',
                          }}
                        >
                          {`${formState.values.reason_myna_cert}`}
                        </InputLabel>
                      </div>
                    </span>
                  </Box>
                  <Box
                    sx={{
                      ml: 4,
                      display:
                        formState.values.relatives === '有り' ? '' : 'none',
                    }}
                  >
                    <Box
                      sx={{ display: count >= 1 ? '' : 'none' }}
                      onClick={() => {
                        if (confirm) {
                          window.location.href =
                            window.location.origin +
                            window.location.pathname +
                            '?next=next2' +
                            branchCheck;
                        } else {
                          setActiveStep(1);
                          window.scrollTo({
                            top: 0,
                            // behavior: 'smooth',
                          });
                        }
                      }}
                    >
                      <InputLabel
                        sx={{
                          mt: 2,
                          color: '#0f1941',
                          fontSize: '1em',
                          fontWeight: 'bold',
                        }}
                      >
                        {'被扶養者1'}
                      </InputLabel>
                      <div>
                        <InputLabel
                          sx={{
                            mt: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'氏名:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            mt: 2,
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesLastnameSi1}　${formState.values.relativesFirstnameSi1}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'氏名(フリガナ):'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesLastnameRubySi1}　${formState.values.relativesFirstnameRubySi1}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'生年月日:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesBirthdaySi1}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'続柄:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesRelationshipSi1}`}
                        </InputLabel>
                      </div>
                    </Box>
                    <Box
                      sx={{ display: count >= 2 ? '' : 'none' }}
                      onClick={() => {
                        if (confirm) {
                          window.location.href =
                            window.location.origin +
                            window.location.pathname +
                            '?next=next2' +
                            branchCheck;
                        } else {
                          setActiveStep(1);
                          window.scrollTo({
                            top: 0,
                            // behavior: 'smooth',
                          });
                        }
                      }}
                    >
                      <InputLabel
                        sx={{
                          mt: 2,
                          color: '#0f1941',
                          fontSize: '1em',
                          fontWeight: 'bold',
                        }}
                      >
                        {'被扶養者2'}
                      </InputLabel>
                      <div>
                        <InputLabel
                          sx={{
                            mt: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'氏名:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            mt: 2,
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesLastnameSi2}　${formState.values.relativesFirstnameSi2}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'氏名(フリガナ):'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesLastnameRubySi2}　${formState.values.relativesFirstnameRubySi2}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'生年月日:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesBirthdaySi2}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'続柄:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesRelationshipSi2}`}
                        </InputLabel>
                      </div>
                    </Box>
                    <Box
                      sx={{ display: count >= 3 ? '' : 'none' }}
                      onClick={() => {
                        if (confirm) {
                          window.location.href =
                            window.location.origin +
                            window.location.pathname +
                            '?next=next2' +
                            branchCheck;
                        } else {
                          setActiveStep(1);
                          window.scrollTo({
                            top: 0,
                            // behavior: 'smooth',
                          });
                        }
                      }}
                    >
                      <InputLabel
                        sx={{
                          mt: 2,
                          color: '#0f1941',
                          fontSize: '1em',
                          fontWeight: 'bold',
                        }}
                      >
                        {'被扶養者3'}
                      </InputLabel>
                      <div>
                        <InputLabel
                          sx={{
                            mt: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'氏名:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            mt: 2,
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesLastnameSi3}　${formState.values.relativesFirstnameSi3}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'氏名(フリガナ):'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesLastnameRubySi3}　${formState.values.relativesFirstnameRubySi3}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'生年月日:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesBirthdaySi3}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'続柄:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesRelationshipSi3}`}
                        </InputLabel>
                      </div>
                    </Box>
                    <Box
                      sx={{ display: count >= 4 ? '' : 'none' }}
                      onClick={() => {
                        if (confirm) {
                          window.location.href =
                            window.location.origin +
                            window.location.pathname +
                            '?next=next2' +
                            branchCheck;
                        } else {
                          setActiveStep(1);
                          window.scrollTo({
                            top: 0,
                            // behavior: 'smooth',
                          });
                        }
                      }}
                    >
                      <InputLabel
                        sx={{
                          mt: 2,
                          color: '#0f1941',
                          fontSize: '1em',
                          fontWeight: 'bold',
                        }}
                      >
                        {'被扶養者4'}
                      </InputLabel>
                      <div>
                        <InputLabel
                          sx={{
                            mt: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'氏名:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            mt: 2,
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesLastnameSi4}　${formState.values.relativesFirstnameSi4}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'氏名(フリガナ):'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesLastnameRubySi4}　${formState.values.relativesFirstnameRubySi4}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'生年月日:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesBirthdaySi4}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'続柄:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesRelationshipSi4}`}
                        </InputLabel>
                      </div>
                    </Box>
                    <Box
                      sx={{ display: count >= 5 ? '' : 'none' }}
                      onClick={() => {
                        if (confirm) {
                          window.location.href =
                            window.location.origin +
                            window.location.pathname +
                            '?next=next2' +
                            branchCheck;
                        } else {
                          setActiveStep(1);
                          window.scrollTo({
                            top: 0,
                            // behavior: 'smooth',
                          });
                        }
                      }}
                    >
                      <InputLabel
                        sx={{
                          mt: 2,
                          color: '#0f1941',
                          fontSize: '1em',
                          fontWeight: 'bold',
                        }}
                      >
                        {'被扶養者5'}
                      </InputLabel>
                      <div>
                        <InputLabel
                          sx={{
                            mt: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'氏名:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            mt: 2,
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesLastnameSi5}　${formState.values.relativesFirstnameSi5}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'氏名(フリガナ):'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesLastnameRubySi5}　${formState.values.relativesFirstnameRubySi5}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'生年月日:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesBirthdaySi5}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'続柄:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesRelationshipSi5}`}
                        </InputLabel>
                      </div>
                    </Box>
                    <Box
                      sx={{ display: count >= 6 ? '' : 'none' }}
                      onClick={() => {
                        if (confirm) {
                          window.location.href =
                            window.location.origin +
                            window.location.pathname +
                            '?next=next2' +
                            branchCheck;
                        } else {
                          setActiveStep(1);
                          window.scrollTo({
                            top: 0,
                            // behavior: 'smooth',
                          });
                        }
                      }}
                    >
                      <InputLabel
                        sx={{
                          mt: 2,
                          color: '#0f1941',
                          fontSize: '1em',
                          fontWeight: 'bold',
                        }}
                      >
                        {'被扶養者6'}
                      </InputLabel>
                      <div>
                        <InputLabel
                          sx={{
                            mt: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'氏名:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            mt: 2,
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesLastnameSi6}　${formState.values.relativesFirstnameSi6}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'氏名(フリガナ):'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesLastnameRubySi6}　${formState.values.relativesFirstnameRubySi6}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'生年月日:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesBirthdaySi6}`}
                        </InputLabel>
                      </div>
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'続柄:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesRelationshipSi6}`}
                        </InputLabel>
                      </div>
                    </Box>
                    <Box
                      sx={{ display: count >= 7 ? '' : 'none' }}
                      onClick={() => {
                        if (confirm) {
                          window.location.href =
                            window.location.origin +
                            window.location.pathname +
                            '?next=next2' +
                            branchCheck;
                        } else {
                          setActiveStep(1);
                          window.scrollTo({
                            top: 0,
                            // behavior: 'smooth',
                          });
                        }
                      }}
                    >
                      <InputLabel
                        sx={{
                          mt: 2,
                          color: '#0f1941',
                          fontSize: '1em',
                          fontWeight: 'bold',
                        }}
                      >
                        {'被扶養者7'}
                      </InputLabel>
                      <div>
                        <InputLabel
                          sx={{
                            mt: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'氏名:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            mt: 2,
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesLastnameSi7}　${formState.values.relativesFirstnameSi7}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'氏名(フリガナ):'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesLastnameRubySi7}　${formState.values.relativesFirstnameRubySi7}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'生年月日:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesBirthdaySi7}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'続柄:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesRelationshipSi7}`}
                        </InputLabel>
                      </div>
                    </Box>
                    <Box
                      sx={{ display: count >= 8 ? '' : 'none' }}
                      onClick={() => {
                        if (confirm) {
                          window.location.href =
                            window.location.origin +
                            window.location.pathname +
                            '?next=next2' +
                            branchCheck;
                        } else {
                          setActiveStep(1);
                          window.scrollTo({
                            top: 0,
                            // behavior: 'smooth',
                          });
                        }
                      }}
                    >
                      <InputLabel
                        sx={{
                          mt: 2,
                          color: '#0f1941',
                          fontSize: '1em',
                          fontWeight: 'bold',
                        }}
                      >
                        {'被扶養者8'}
                      </InputLabel>
                      <div>
                        <InputLabel
                          sx={{
                            mt: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'氏名:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            mt: 2,
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesLastnameSi8}　${formState.values.relativesFirstnameSi8}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'氏名(フリガナ):'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesLastnameRubySi8}　${formState.values.relativesFirstnameRubySi8}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'生年月日:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesBirthdaySi8}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'続柄:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesRelationshipSi8}`}
                        </InputLabel>
                      </div>
                    </Box>
                    <Box
                      sx={{ display: count >= 9 ? '' : 'none' }}
                      onClick={() => {
                        if (confirm) {
                          window.location.href =
                            window.location.origin +
                            window.location.pathname +
                            '?next=next2' +
                            branchCheck;
                        } else {
                          setActiveStep(1);
                          window.scrollTo({
                            top: 0,
                            // behavior: 'smooth',
                          });
                        }
                      }}
                    >
                      <InputLabel
                        sx={{
                          mt: 2,
                          color: '#0f1941',
                          fontSize: '1em',
                          fontWeight: 'bold',
                        }}
                      >
                        {'被扶養者9'}
                      </InputLabel>
                      <div>
                        <InputLabel
                          sx={{
                            mt: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'氏名:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            mt: 2,
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesLastnameSi9}　${formState.values.relativesFirstnameSi9}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'氏名(フリガナ):'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesLastnameRubySi9}　${formState.values.relativesFirstnameRubySi9}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'生年月日:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesBirthdaySi9}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'続柄:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesRelationshipSi9}`}
                        </InputLabel>
                      </div>
                    </Box>
                    <Box
                      sx={{ display: count >= 10 ? '' : 'none' }}
                      onClick={() => {
                        if (confirm) {
                          window.location.href =
                            window.location.origin +
                            window.location.pathname +
                            '?next=next2' +
                            branchCheck;
                        } else {
                          setActiveStep(1);
                          window.scrollTo({
                            top: 0,
                            // behavior: 'smooth',
                          });
                        }
                      }}
                    >
                      <InputLabel
                        sx={{
                          mt: 2,
                          color: '#0f1941',
                          fontSize: '1em',
                          fontWeight: 'bold',
                        }}
                      >
                        {'被扶養者10'}
                      </InputLabel>
                      <div>
                        <InputLabel
                          sx={{
                            mt: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'氏名:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            mt: 2,
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesLastnameSi10}　${formState.values.relativesFirstnameSi10}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'氏名(フリガナ):'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesLastnameRubySi10}　${formState.values.relativesFirstnameRubySi10}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'生年月日:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesBirthdaySi10}`}
                        </InputLabel>
                      </div>
                      <Divider />
                      <div>
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'続柄:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {`${formState.values.relativesRelationshipSi10}`}
                        </InputLabel>
                      </div>
                    </Box>
                  </Box>
                  <Box
                    sx={{ ml: 4 }}
                    onClick={() => {
                      if (confirm) {
                        window.location.href =
                          window.location.origin +
                          window.location.pathname +
                          '?next=next3' +
                          branchCheck;
                      } else {
                        setActiveStep(2);
                        window.scrollTo({
                          top: 0,
                          // behavior: 'smooth',
                        });
                      }
                    }}
                  >
                    <InputLabel
                      sx={{
                        mt: 2,
                        color: '#0f1941',
                        fontSize: '1em',
                        fontWeight: 'bold',
                      }}
                    >
                      {'雇用保険'}
                    </InputLabel>
                    <div>
                      <InputLabel
                        sx={{
                          mt: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          width: '40%',
                          textAlign: 'right',
                          display: 'inline-block',
                        }}
                      >
                        {'加入対象:'}
                      </InputLabel>
                      <InputLabel
                        sx={{
                          mt: 2,
                          ml: 2,
                          color: '#0f1941',
                          fontSize: '0.9em',
                          textDecoration: 'underline',
                          width: '50%',
                          display: 'inline-block',
                        }}
                      >
                        {`${formState.values.unemploymentTarget}`}
                      </InputLabel>
                    </div>
                    <div>
                      <Divider />
                      <span
                        style={{
                          display: formState.values.knowUnemploymentNumber
                            ? ''
                            : 'none',
                        }}
                      >
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'雇用保険番号:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {formState.values.knowUnemploymentNumber === `はい`
                            ? `${formState.values.unemploymentNumber1} - ${formState.values.unemploymentNumber2} - ${formState.values.unemploymentNumber3}`
                            : `分からない`}
                        </InputLabel>
                      </span>
                      <span
                        style={{
                          display:
                            formState.values.knowUnemploymentNumber === 'いいえ'
                              ? ''
                              : 'none',
                        }}
                      >
                        <Divider />
                        <InputLabel
                          sx={{
                            color: '#0f1941',
                            fontSize: '0.9em',
                            width: '40%',
                            textAlign: 'right',
                            display: 'inline-block',
                          }}
                        >
                          {'加入実績:'}
                        </InputLabel>
                        <InputLabel
                          sx={{
                            ml: 2,
                            color: '#0f1941',
                            fontSize: '0.9em',
                            textDecoration: 'underline',
                            width: '50%',
                            display: 'inline-block',
                          }}
                        >
                          {formState.values.getInsurance === `有り`
                            ? `有り`
                            : `無し`}
                        </InputLabel>
                        <Divider />
                        <span
                          style={{
                            display:
                              formState.values.getInsurance === '有り'
                                ? ''
                                : 'none',
                          }}
                        >
                          <InputLabel
                            sx={{
                              color: '#0f1941',
                              fontSize: '0.9em',
                              width: '40%',
                              textAlign: 'right',
                              display: 'inline-block',
                            }}
                          >
                            {'企業名:'}
                          </InputLabel>
                          <InputLabel
                            sx={{
                              ml: 2,
                              color: '#0f1941',
                              fontSize: '0.9em',
                              textDecoration: 'underline',
                              width: '50%',
                              display: 'inline-block',
                            }}
                          >
                            {`${formState.values.companyName}`}
                          </InputLabel>
                        </span>
                      </span>
                    </div>
                  </Box>
                </span>
              </div>
              <Box sx={{ mt: 9 }} />
            </div>
          </div>
        ) : (
          ''
        )}
        {activeStep === 4 ? (
          <div>
            <div
              style={{
                border: '1px solid rgba(215,215,215,1)',
                marginTop: '1em',
                padding: '1em',
              }}
            >
              <span>
                <span>
                  お疲れ様でした。
                  <br />
                  こちらのページでの入力は完了となります。
                  <br />
                  <br />
                  <span
                    style={{
                      color: '#e74536',
                      display: branch === 'cs' ? 'none' : '',
                    }}
                  >
                    社会保険手続きについて、ご登録翌日以降に訂正がある場合は以下にご連絡下さい。
                    <br />
                    <br />
                    【社会保険センター】
                    <br />
                    0120-940-911（平日9：00～18：00）
                  </span>
                  <span
                    style={{
                      color: '#e74536',
                      display: branch === 'cs' ? '' : 'none',
                    }}
                  >
                    社会保険手続きについて、ご登録翌日以降に訂正がある場合は以下にご連絡下さい。
                    <br />
                    <br />
                    【新宿業務管理部社会保険センター】
                    <br />
                    03-5326-7258
                    <br />
                    （平日 9：00～18：00）
                  </span>
                  <br />
                  <br />
                  Wワーク（同時に複数の社会保険に加入される場合）の方は
                  社会保険センターまでご連絡ください。
                  <br />
                  <span
                    style={{
                      display:
                        formState.values.staffCodeDigit === '6桁' ? 'none' : '',
                    }}
                  >
                    <br />
                    <br />
                    <span
                      style={{
                        fontWeight: 'bold',
                      }}
                    >
                      -マイナンバーご登録のお願い-
                    </span>
                    <br />
                    Fast numberからマイナンバーのご登録がお済みでしょうか？
                    <br />
                    未だお済みではない場合、お手数ですが以下からアプリをダウンロードしていただき、ご登録をお願いいたします。
                    <br />
                    <br />
                    Fast numberアプリをダウンロード
                    <br />
                    <span
                      style={{
                        color: '#1d6ad4',
                      }}
                      onClick={() => {
                        liff.openWindow({
                          url: `https://apps.apple.com/jp/app/fastnumber-ファストナンバー/id1054252418`,
                          external: true,
                        });
                      }}
                    >
                      iOS
                    </span>
                    <br />
                    <span
                      style={{
                        color: '#1d6ad4',
                      }}
                      onClick={() => {
                        liff.openWindow({
                          url: `https://play.google.com/store/apps/details?id=jp.co.toppanf.fastnumber&hl=ja&gl=US&pli=1`,
                          external: true,
                        });
                      }}
                    >
                      Android
                    </span>
                    <br />
                    <br />
                    <span
                      style={{
                        display:
                          formState.values.staffCodeDigit === '8桁'
                            ? 'none'
                            : '',
                      }}
                    >
                      ＊
                      ログインに必要なID/パスワードはコンサルタントからご案内させて頂いております。
                    </span>
                    <span
                      style={{
                        display:
                          formState.values.staffCodeDigit === '8桁'
                            ? ''
                            : 'none',
                      }}
                    >
                      ＊ログインに必要なID/パスワードはご登録のメールアドレスにお送りさせていただいております。
                    </span>
                  </span>
                </span>
              </span>
              <br />
            </div>
            <Box sx={{ mt: 9 }} />
          </div>
        ) : (
          ''
        )}
        <Grid
          item
          xs={7}
          style={{
            textAlign: 'center',
            display: confirm ? 'none' : '',
          }}
        >
          <AppBar
            position="fixed"
            color="primary"
            sx={{
              top: 'auto',
              bottom: -2,
              backgroundColor: '#1d6ad4',
            }}
          >
            <Grid
              container
              style={{
                height: 55,
              }}
            >
              {activeStep === 4 ||
              (activeStep === 0 && !inputStart && next === 'default') ? (
                <React.Fragment>
                  <Grid
                    item
                    sx={{ width: '100%' }}
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    <Button
                      color="inherit"
                      aria-label="next key"
                      sx={{
                        marginTop: 1,
                        width: '100%',
                      }}
                      /*disabled={applied}*/
                      onClick={
                        !inputStart
                          ? handleStart
                          : () => {
                              liff.closeWindow();
                            }
                      }
                    >
                      <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
                        {!inputStart ? '入力を開始する' : '完了'}
                      </span>
                    </Button>
                  </Grid>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Grid
                    item
                    xs={6}
                    style={{
                      backgroundColor: '#d9d9d9',
                      textAlign: 'center',
                    }}
                  >
                    <Button
                      color="inherit"
                      aria-label="back key"
                      sx={{
                        backgroundColor: '#d9d9d9',
                        marginTop: 1,
                        width: '100%',
                        color: '#1d6ad4',
                      }}
                      // disabled={activeStep === 0}
                      onClick={() => {
                        activeStep === 0 && edit
                          ? (window.location.href =
                              window.location.origin +
                              window.location.pathname +
                              '?prev=edit' +
                              branchCheck)
                          : activeStep === 0 && DR
                          ? (window.location.href =
                              window.location.origin +
                              window.location.pathname +
                              '?prev=prev6')
                          : activeStep === 0 && !DR
                          ? (window.location.href =
                              window.location.origin +
                              window.location.pathname +
                              '?prev=prev5' +
                              branchCheck)
                          : handleBack();
                      }}
                    >
                      <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
                        前へ
                      </span>
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    <Button
                      color="inherit"
                      aria-label="next key"
                      sx={{
                        marginTop: 1,
                        width: '100%',
                      }}
                      onClick={activeStep === 3 ? handleOpen : handleNext}
                    >
                      <span
                        style={{
                          fontSize: '16px',
                          fontWeight: 'bold',
                          width: '50%',
                        }}
                      >
                        {activeStep === 3 ? '登録' : '次へ'}
                      </span>
                    </Button>
                  </Grid>
                </React.Fragment>
              )}
            </Grid>
            {/*</Toolbar>*/}
          </AppBar>
        </Grid>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'最終確認'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            登録内容に間違いなければ登録してください。
            <br />
            <br />
            <span
              style={{
                fontSize: '0.9em',
                color: '#e74536',
              }}
            >
              ※LINEの登録については、初回のみとし登録した内容の変更期限は当日限りとなります。以降の申請についてはお電話にてご連絡での申請になりますのでご了承下さい。
            </span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>戻る</Button>
          <Button onClick={handleClickOpen} autoFocus>
            登録する
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open2}
        onClose={handleClose2}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {formState.values.unemploymentTarget === 'いいえ' &&
          formState.values.socialTarget === 'いいえ'
            ? '申請不要'
            : '必須項目が未入力'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {errMsg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose2} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open3}
        onClose={handleClose3}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'被扶養者をご入力された方へ'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            被扶養者申請のための申請書類を追ってご郵送いたします。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose3} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open4}
        onClose={handleClose4}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'申請不要'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <span
              style={{
                fontSize: '0.9em',
                color: '#e74536',
              }}
            >
              {errMsg}
            </span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose4}>戻る</Button>
          <Button
            onClick={() => {
              window.location.href =
                window.location.origin +
                window.location.pathname +
                '?confirm=true' +
                branchCheck;
            }}
            autoFocus
          >
            スキップする
          </Button>
        </DialogActions>
      </Dialog>
      <Popover
        open={showPopover}
        anchorEl={popoverAnchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <div
          style={{
            color: '#0f1941',
            fontSize: '14px',
            padding: '1em',
          }}
        >
          {popovers[popoverContent]}
        </div>
      </Popover>
    </span>
  );
};

export default SocialInsuranceForm;
